import * as BABYLON from "babylonjs";

export const DEFAULT_PROJECTED_TEX_LAYER_MASK = 0x10000000;
export const DEFAULT_LAYER_MASK = 0x0fffffff;

const defaultParams = {
  position: new BABYLON.Vector3(0, 0, 3),
  target: new BABYLON.Vector3(0, 0, 0),
  width: 1920,
  height: 1080,
  doNotChangeAspectRatio: false,
  generateMipMaps: true,
  useProjectedTextureLayerMask: true,
  layerMask: DEFAULT_PROJECTED_TEX_LAYER_MASK,
};

export class ProjectedTexture extends BABYLON.RenderTargetTexture {
  constructor(scene, camera, params) {
    params = Object.assign(defaultParams, params);

    super(
      "renderTargetTexture",
      { width: params.width, height: params.height },
      scene,
      params.generateMipMaps,
      params.doNotChangeAspectRatio
    );

    this._layerMask = params.layerMask;

    if (params.useProjectedTextureLayerMask) {
      camera.layerMask = this._layerMask;
    }

    this.scene = scene;
    this.camera = camera;
    this.activeCamera = camera;

    this.vScale = 1;
    this.uScale = 1;
  }

  addRenderedMesh(mesh) {
    mesh.layerMask = DEFAULT_PROJECTED_TEX_LAYER_MASK;
    this.renderList.push(mesh);
  }

  removeRendererMesh(mesh) {
    mesh.layerMask = DEFAULT_LAYER_MASK;
    const idx = this.renderList.indexOf(mesh);
    if (idx > -1) {
      this.renderList.splice(idx, 1);
    }
  }

  get layerMask() {
    return this._layerMask;
  }

  set layerMask(value) {
    this.renderList.forEach((el) => {
      el.layerMask = value;
    });

    this._layerMask = value;
  }
}
