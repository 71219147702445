import { io } from "socket.io-client";
import { params as kinevoParams } from "@/scene/kinevo/KinevoController";
import { throttle } from "throttle-debounce";

export class SceneInteractionSynchronizer {
  constructor(conferenceName, pointerHelper, kinevoController, playerHelper) {
    if (!conferenceName) {
      throw new Error("Conference name must not be empty");
    }
    this._pointerHelper = pointerHelper;
    this._kinevoController = kinevoController;
    this._playerHelper = playerHelper;
    this._socket = io("wss://showroom-demo.apilia.pl", {
      path: "/pointer-websocket",
      query: { conference: conferenceName },
    });

    this._socket.on("initial_data", (data) => {
      console.log("Initial position", data);
      this._updatePointer(data.pointerParams);
      this._initKinevoData(data.kinevoParams);
      this._initPlayers(data.players);
    });

    this._socket.on("update_pointer_pos", (params) => {
      this._updatePointer(params);
    });

    this._socket.on("update_kinevo_params", (params) => {
      this._updateKinevoParams(params);
    });

    this._socket.on(
      "update_kinevo_bounding_boxes",
      (forceAnimationLoopBeforeRefresh) => {
        this._updateKinevoBoundingBoxes(forceAnimationLoopBeforeRefresh);
      }
    );

    this._socket.on("update_kinevo_pos", (params) => {
      this._updateKinevoPosition(params);
    });

    this._socket.on("update_kinevo_mass", (params) => {
      this._updateKinevoMass(params);
    });

    this._socket.on("update_kinevo_feature", () => {
      this._updateKinevoFeature();
    });

    this._socket.on("update_kinevo_activation_state", () => {
      this._updateKinevoActivationState();
    });
    this._socket.on("show_kinevo_toast", (text) => {
      this._showToast(text);
    });

    this._socket.on("update_kinevo_focus_state", (params) => {
      this._updateKinevoFocusState(params);
    });

    this._socket.on("update_player_move", (participantId, params) => {
      this._updatePlayerMove(participantId, params);
    });

    this._socket.on("disconnect_player", (participantId) => {
      this._disconnectPlayer(participantId);
    });

    playerHelper.setOnLocalPlayerUpdate((participantId, params) => {
      this._socket.emit("update_player_move", participantId, params);
    });

    playerHelper.setOnPlayerDisconnect((participantId) => {
      this._socket.emit("disconnect_player", participantId);
    });

    pointerHelper.setOnPosChangeListener((params) => {
      this._socket.emit("update_pointer_pos", params);
    });

    kinevoController.setOnParamsChangeListener((params) => {
      this._socket.emit("update_kinevo_params", params);
    });
    kinevoController.setOnRefreshBoundingBoxesListener(
      (forceAnimationLoopBeforeRefresh) => {
        this._socket.emit(
          "update_kinevo_bounding_boxes",
          forceAnimationLoopBeforeRefresh
        );
      }
    );
    kinevoController.setOnPositionChangeListener(
      throttle(33, (params) => {
        this._socket.emit("update_kinevo_pos", params);
      })
    );
    kinevoController.setOnMassChangeListener((params) => {
      this._socket.emit("update_kinevo_mass", params);
    });
    kinevoController.setOnActivationStateChangeListener((params) => {
      this._socket.emit("update_kinevo_activation_state", params);
    });
    kinevoController.setOnFocusStateChangeListener((params) => {
      this._socket.emit("update_kinevo_focus_state", params);
    });
    kinevoController.setOnFeatureChangeListener((params) => {
      this._socket.emit("update_kinevo_feature", params);
    });
    kinevoController.setOnToastShow((text) => {
      this._socket.emit("show_kinevo_toast", text);
    });
  }

  _updatePointer(params) {
    this._pointerHelper.updatePosition(params);
  }

  _updateKinevoParams(params, updateButtons = true) {
    Object.assign(kinevoParams, params);

    if (updateButtons) {
      this._kinevoController.updateButtonsVisibility();
    }
  }

  _updateKinevoActivationState() {
    if (kinevoParams.active) {
      this._kinevoController.deactivate(false);
    } else {
      this._kinevoController.activate(false);
    }
  }

  _updateKinevoPosition(params) {
    this._updateKinevoParams(params, false);

    this._kinevoController.updateBoneAnimations(false);
  }

  _updateKinevoMass(params) {
    this._updateKinevoParams(params, false);

    this._kinevoController.updateBonesMass(false);
  }

  _showToast(text) {
    this._kinevoController.showToast(text, false);
  }

  _updateKinevoFocusState(params) {
    this._updateKinevoParams(params);

    if (kinevoParams.pointLockFocus) {
      this._kinevoController.showArrow();
    } else {
      this._kinevoController.hideArrow();
    }
  }

  _updateKinevoBoundingBoxes(forceAnimationLoopBeforeRefresh) {
    this._kinevoController.refreshKinevoBoundingBoxes(
      false,
      forceAnimationLoopBeforeRefresh
    );
  }

  _updateKinevoFeature() {
    this._kinevoController.updateFeature(false);
  }

  _initKinevoData(params) {
    this._updateKinevoParams(params);

    if (params.active) {
      this._kinevoController.activate(false, false);
      this._kinevoController.updateBonesMass(false);
      this._kinevoController.updateBoneAnimations(false);
      this._kinevoController.forceLoopAnimation();
      this._updateKinevoBoundingBoxes();

      if (params.pointLockFocus) {
        this._kinevoController.showArrow();
      }
    }
  }

  _updatePlayerMove(participantId, params) {
    this._playerHelper.movePlayerByParticipantId(participantId, params);
  }

  _disconnectPlayer(participantId) {
    this._playerHelper.removePlayerByParticipantId(participantId);
  }

  _initPlayers(players) {
    for (const [key, value] of Object.entries(players)) {
      this._playerHelper.movePlayerByParticipantId(key, value);
    }
  }

  close() {
    if (this._socket) {
      this._socket.close();
    }
  }
}
