import { isTouchDevice } from "@/scene/utils";

export class PointerLockHelper {
  constructor({ domElement, camera }) {
    this.domElement = domElement;
    this.camera = camera;

    this.isLocked = false;

    this.isTouchDevice = isTouchDevice();

    this._setPointerLock();
  }

  _setPointerLock() {
    this.domElement.requestPointerLock =
      this.domElement.requestPointerLock ||
      this.domElement.msRequestPointerLock ||
      this.domElement.mozRequestPointerLock ||
      this.domElement.webkitRequestPointerLock;

    document.exitPointerLock =
      document.exitPointerLock || document.mozExitPointerLock;
  }

  _listenForPointerLock = () => {
    if (!this.isLocked && this.domElement.requestPointerLock) {
      this.domElement.requestPointerLock();

      if (!this.isTouchDevice) {
        this.camera.attachControl();
      }
    }
  };

  _onPointerLockChange = () => {
    this.isLocked = !!(
      document.mozPointerLockElement ||
      document.webkitPointerLockElement ||
      document.msPointerLockElement ||
      document.pointerLockElement ||
      null
    );

    if (!this.isLocked && !this.isTouchDevice) {
      this.camera.detachControl();
    }
  };

  assignPointerLock() {
    if (this.isTouchDevice) {
      this.camera.attachControl();
    }

    this.domElement.addEventListener(
      "pointerup",
      this._listenForPointerLock,
      false
    );

    document.addEventListener(
      "pointerlockchange",
      this._onPointerLockChange,
      false
    );
    document.addEventListener(
      "mspointerlockchange",
      this._onPointerLockChange,
      false
    );
    document.addEventListener(
      "mozpointerlockchange",
      this._onPointerLockChange,
      false
    );
    document.addEventListener(
      "webkitpointerlockchange",
      this._onPointerLockChange,
      false
    );
  }

  disposePointerLock() {
    this.domElement.removeEventListener(
      "mousedown",
      this._listenForPointerLock,
      false
    );

    document.removeEventListener(
      "pointerlockchange",
      this._onPointerLockChange,
      false
    );
    document.removeEventListener(
      "mspointerlockchange",
      this._onPointerLockChange,
      false
    );
    document.removeEventListener(
      "mozpointerlockchange",
      this._onPointerLockChange,
      false
    );
    document.removeEventListener(
      "webkitpointerlockchange",
      this._onPointerLockChange,
      false
    );
  }

  exitPointerLock() {
    document.exitPointerLock();
  }

  enterPointerLock() {
    this.domElement.requestPointerLock();
  }
}
