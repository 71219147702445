/* global JitsiMeetJS */

const JITSI_DOMAIN = 'showroom-jitsi.apilia.pl';
export const LOCAL_USER_ID = 'local';

export class JitsiMeet {
    static EVT_CONFERENCE_JOINED = 'conference_joined';
    /**
     * Event arg (object) - { participantId, isLocal, isModerator }
     * @type {string}
     */
    static EVT_PARTICIPANT_JOINED = 'participant_joined';
    /**
     * Event arg (string) - participantId
     * @type {string}
     */
    static EVT_PARTICIPANT_LEFT = 'participant_left';
    /**
     * Event arg (object) - { participantId, isLocal, isMuted, mediaElement }
     * @type {string}
     */
    static EVT_VIDEO_MUTED = 'video_muted';
    static EVT_AUDIO_MUTED = 'audio_muted';
    connection = null;
    conference = null;
    _tracks = {};
    _listeners = [];

    constructor({ attachAudio = true }) {
      this._attachAudio = attachAudio;
    }

    join (confName, password = undefined) {
        if (!this.connection) {
            console.error('You need to call "connect" method before joining a conference');
            return;
        }
        this._joinConference(confName, password);
    }

    connect (adminUsername = null, adminPassword = null) {
        return new Promise((resolve, reject) => {
            this._connectInternal(resolve, reject, adminUsername, adminPassword);
        });
    }

    _connectInternal (resolve, reject, adminUsername, adminPassword) {
        this._boundLeave = this.leave.bind(this);
        window.addEventListener('beforeunload', this._boundLeave);
        // $(window).bind('unload', unload);

        JitsiMeetJS.init({
            disableAudioLevels: true,
        });
        JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.INFO);
        this.connection = new JitsiMeetJS.JitsiConnection(null, null, {
            serviceUrl: `wss://${JITSI_DOMAIN}/xmpp-websocket`,
            // serviceUrl: `https://${JITSI_DOMAIN}/http-bind`,
            hosts: {
                domain: JITSI_DOMAIN,
                muc: `conference.${JITSI_DOMAIN}`,
            },
        });

        // this._boundJoinConference = this._joinConference.bind(this, confName, password);
        this.connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            resolve
        );
        this._boundOnConnectionFailed = this.onConnectionFailed.bind(this, reject);
        this.connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED,
            this._boundOnConnectionFailed
        );
        this._boundOnDisconnect = this.onDisconnect.bind(this);
        this.connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
            this._boundOnDisconnect
        );

        let connectOptions = {};
        if (adminUsername && adminPassword) {
            connectOptions = {
                id: `${adminUsername}@${JITSI_DOMAIN}`,
                password: adminPassword,
            };
        }

        this.connection.connect(connectOptions);
    }

    _joinConference (confName, password) {
        console.info("Connected!", this.connection);
        this.conference = this.connection.initJitsiConference(confName, {});
        this.conference.on(JitsiMeetJS.events.conference.TRACK_ADDED, (track) => {
            this._handleRemoteTrackAdded(track);
        });
        this.conference.on(JitsiMeetJS.events.conference.USER_JOINED, (id, user) => this._handleUserJoined(id, user));
        this.conference.on(JitsiMeetJS.events.conference.USER_LEFT, (id) => this._handleUserLeft(id));
        this.conference.on(JitsiMeetJS.events.conference.TRACK_REMOVED, (track) => {
            this._handleRemoteTrackRemoved(track);
        });
        this.conference.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, () => {
            this._emit(JitsiMeet.EVT_CONFERENCE_JOINED);
            this._initLocalTracks();
        });
        this.conference.join(password);
    }

    _initLocalTracks () {
        JitsiMeetJS.createLocalTracks({ devices: ['audio', 'video'] })
            .then(tracks => this._onLocalTracks(tracks))
            .catch(error => {
                throw error;
            });

        this._emit(JitsiMeet.EVT_PARTICIPANT_JOINED, {
            participantId: LOCAL_USER_ID,
            isLocal: true,
            isModerator: this.conference.isModerator(),
        });
    }

    _onLocalTracks (tracks) {
        for (let i = 0; i < tracks.length; i++) {
            const track = tracks[i];
            track.addEventListener(
                JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
                audioLevel => console.log(`Audio Level local: ${audioLevel}`));
            track.addEventListener(
                JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
                (mutedTrack) => this._emitMuteEvent(LOCAL_USER_ID, mutedTrack));
            track.addEventListener(
                JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
                () => {
                    console.error('Local track stopped');
                });
            track.addEventListener(
                JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
                deviceId =>
                    console.log(
                        `track audio output device was changed to ${deviceId}`));
            if (!this._tracks[LOCAL_USER_ID]) {
                this._tracks[LOCAL_USER_ID] = {};
            }
            this._removeExistingParticipantTrack(LOCAL_USER_ID, track);
            this._tracks[LOCAL_USER_ID][track.getType()] = track;

            // we don't want to hear ourselves
            if (track.getType() !== 'audio') {
                this._attachTrackAndEmit(LOCAL_USER_ID, track, this.conference.isModerator());
            }
            this.conference.addTrack(track);
        }
    }

    _emitMuteEvent (participantId, mutedTrack) {
        const eventData = {
            type: mutedTrack.getType(),
            participantId,
            isMuted: mutedTrack.isMuted(),
            isLocal: mutedTrack.isLocal(),
            mediaElement: document.getElementById(`${participantId}${mutedTrack.getType()}`),
        };
        if (mutedTrack.getType() === 'audio') {
            this._emit(JitsiMeet.EVT_AUDIO_MUTED, eventData);
        } else {
            this._emit(JitsiMeet.EVT_VIDEO_MUTED, eventData);
        }
    }

    _handleUserJoined (participantId) {
        if (!this._tracks[participantId]) {
            this._tracks[participantId] = {};
        }

        this._emit(JitsiMeet.EVT_PARTICIPANT_JOINED, {
            participantId,
            isLocal: false,
            isModerator: this._isModerator(participantId),
        });
    }

    _handleRemoteTrackAdded (track) {
        if (track.isLocal()) {
            return;
        }
        const participant = track.getParticipantId();

        if (!this._tracks[participant]) {
            this._tracks[participant] = {};
        }

        console.log('Adding remote track', participant, track.getType());

        this._removeExistingParticipantTrack(participant, track);

        this._tracks[participant][track.getType()] = track;

        if (!track.listenersSet) {
            track.listenersSet = true;
            track.addEventListener(
                JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
                (audioLevel) => console.log(`Audio Level remote: ${audioLevel}`)
            );
            console.log('Adding muted event listener', participant, track.getTrackId(), track.getId());
            track.addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, mutedTrack =>
                this._emitMuteEvent(participant, mutedTrack)
            );
            track.addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () =>
                console.log("remote track stoped")
            );
            track.addEventListener(
                JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
                (deviceId) =>
                    console.log(`track audio output device was changed to ${deviceId}`)
            );
        }

        this._attachTrackAndEmit(participant, track, this._isModerator(participant));
    }

    _attachTrackAndEmit (participantId, track/*, isModerator*/) {
        const mediaElementId = `${participantId}${track.getType()}`;
        let mediaElement = document.getElementById(mediaElementId);
        if (!mediaElement) {
            mediaElement = this._createElement(track.getType(), mediaElementId);
        }
        track.attach(mediaElement);
        console.log('Attaching track', participantId, track, mediaElement);
        this._emitMuteEvent(participantId, track);
    }

    _removeExistingParticipantTrack (participantId, track) {
        if (!this._tracks[participantId]) {
            return;
        }

        const currentTrack = this._tracks[participantId][track.getType()];
        if (currentTrack) {
            this._detachTrack(participantId, currentTrack);
            this._tracks[participantId][track.getType()] = null;

            /*if (currentTrack.isLocal()) {
                console.error('Removing local track');
                this.conference.removeTrack(currentTrack);
            }*/
        }
    }

    _handleRemoteTrackRemoved (track) {
        const participantId = track.getParticipantId();
        console.log('Remote track removed', participantId, track.getType());
        if (!this._tracks[participantId]) {
            return;
        }
        const currentTrack = this._tracks[participantId][track.getType()];
        if (currentTrack) {
            this._detachTrack(participantId, currentTrack, true);
            this._tracks[participantId][track.getType()] = null;
        }
    }

    _handleUserLeft (id) {
        console.log("User left", id);
        if (!this._tracks[id]) {
            return;
        }
        delete this._tracks[id];
        this._emit(JitsiMeet.EVT_PARTICIPANT_LEFT, id);
    }

    _detachTrack (participantId, track, removeElement = false) {
        const element = document.getElementById(`${participantId}${track.getType()}`);
        if (element) {
            track.detach(element);
            if (removeElement) {
                element.remove();
            }
        }
    }

    onDisconnect () {
        console.log("Disconnected!");
        /* this.connection.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            this._boundJoinConference
        ); */
        this.connection.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED,
            this._boundOnConnectionFailed
        );
        this.connection.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
            this._boundOnDisconnect
        );
        this.connection = null;
        this.conference = null;
    }

    onConnectionFailed (reject, e) {
        console.error('Connection failed', e)
        this.connection = null;
        reject(e);
    }

    _createElement (type, id) {
        const element = document.createElement(type);
        element.autoplay = type === 'audio' ? this._attachAudio : true;
        element.setAttribute('id', id);
        document.body.appendChild(element);
        return element;
    }

    leave () {
        console.log('Jitsi leaving');
        window.removeEventListener('beforeunload', this._boundLeave);
        this._callOnLocalTrack('all', (tracks) => {
            [tracks.video, tracks.audio].forEach(track => {
                if (track) {
                    track.dispose();
                }
            });
        });
        if (this.conference) {
            this.conference.leave();
        }
        if (this.connection) {
            this.connection.disconnect();
        }
    }

    addEventListener (name, handler) {
        this._listeners.push({ name, handler });
    }

    removeEventListener (name, handler) {
        this._listeners = this._listeners.filter(l => l.name !== name || l.handler !== handler);
    }

    _emit (name, ...args) {
        this._listeners.filter(l => l.name === name)
            .forEach(e => e.handler(...args));
    }

    _isModerator (participantId) {
        return this.conference
            .getParticipants()
            .some((p) => p.getId() === participantId && p.getRole() === "moderator");
    }

    muteAudio () {
        return this._callOnLocalTrack('audio', (track) => {
            return track.mute();
        }, () => Promise.resolve());
    }

    unmuteAudio () {
        return this._callOnLocalTrack('audio', (track) => {
            return track.unmute();
        }, () => Promise.resolve());
    }

    muteVideo () {
        return this._callOnLocalTrack('video', (track) => {
            return track.mute();
        }, () => Promise.resolve());
    }

    unmuteVideo () {
        return this._callOnLocalTrack('video', (track) => {
            return track.unmute()
        }, () => Promise.resolve());
    }

    isAudioMuted () {
        return !!this._callOnLocalTrack('audio', (track) => {
            return track.isMuted();
        });
    }

    isVideoMuted () {
        return !!this._callOnLocalTrack('video', (track) => {
            return track.isMuted();
        });
    }

    _callOnLocalTrack (trackType, callback, defaultValueSupplier = () => {
    }) {
        const localTracks = this._tracks[LOCAL_USER_ID];
        if (localTracks) {
            if (trackType === 'all') {
                return callback(localTracks);
            } else if (localTracks[trackType]) {
                return callback(localTracks[trackType]);
            }
        }

        return defaultValueSupplier();
    }

    getUserId() {
      if (!this.conference) {
        throw new Error('Conference is not initialized')
      }

      return this.conference.myUserId()
    }
}
