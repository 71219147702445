<template>
  <div
    :class="['kinevo-panel', isActive && 'kinevo-panel--power-on']"
    :style="getTransformProperty"
  >
    <div class="kinevo-panel__power-info">
      <p>{{ getPowerText }}</p>
      <PowerIcon />
    </div>
    <transition name="fade">
      <div class="kinevo-panel__power-on-info" v-if="isActive">
        <p>{{ getFeature }}</p>
        <p>Mass: {{ getRoundedMass }}</p>
        <transition-group name="fade">
          <p v-if="isLockedToAxes" key="lockToAxes">Locked to axes</p>
          <p v-if="isFocused" key="focus">Focused</p>
        </transition-group>
      </div>
    </transition>
  </div>
</template>

<script>
import { params, pointLockFeature } from "@/scene/kinevo/KinevoController";
import { round } from "@/scene/utils";
import PowerIcon from "vue-material-design-icons/Power";

export default {
  name: "KinevoPanel",
  components: {
    PowerIcon,
  },
  computed: {
    isActive() {
      return params.active;
    },
    getPowerText() {
      return this.isActive ? "POWER ON" : "POWER OFF";
    },
    getFeature() {
      return params.feature === pointLockFeature
        ? "PointLock"
        : "PositionMemory";
    },
    getRoundedMass() {
      return round(params.mass, 2);
    },
    isLockedToAxes() {
      return params.lockToAxes;
    },
    isFocused() {
      return params.pointLockFocus;
    },
    getTransformProperty() {
      let xOffset = -80;
      if (this.isLockedToAxes) xOffset -= 30;
      if (this.isFocused) xOffset -= 30;
      return `--transform: ${xOffset}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
.kinevo-panel {
  position: fixed;
  right: 50px;
  bottom: 30px;
  width: 180px;
  color: #ffffff;
  opacity: 0.4;

  transition: 0.5s ease-in-out all;
  font-size: 1.1875rem;
  font-family: Arial, sans-serif;

  p {
    padding: 5px 0;
  }

  &--power-on {
    transform: translateY(var(--transform));
    opacity: 0.75;
  }

  &__power-info {
    font-weight: bold;
    display: flex;
    align-items: center;

    p {
      font-size: 1.3125rem;
    }

    svg {
      width: 32px;
      height: 32px;
      margin-left: 8px;
    }
  }

  &__power-on-info {
    position: absolute;
    top: 40px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
