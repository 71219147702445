import * as BABYLON from "babylonjs";
import { Screen } from "@/scene/screens/Screen";
import { Speaker } from "@/scene/speakers/Speaker";

const defaultParams = {
  containerYOffset: -1,
  speakerYOffset: 1,
  speedRatio: 1,
  hasScreen: true,
};

export class Player {
  constructor({
    participantId,
    modelContainer,
    scene,
    gui,
    position,
    yRotation,
    params,
  }) {
    this._container = new BABYLON.TransformNode(`container-${participantId}`);

    this._scene = scene;
    this._gui = gui;
    this.participantId = participantId;
    this._isDirty = false;

    const instantiatedModel = modelContainer.instantiateModelsToScene(
      (name) => `${name}-${participantId}`
    );
    this._setupModel(instantiatedModel);

    this._video = {
      mediaScreen: null,
      isMuted: true,
      screen: null,
    };

    this._audio = {
      speaker: null,
      isMuted: true,
    };

    Object.assign(this, defaultParams, params);

    if (this.hasScreen) {
      this._setupScreen();
    }

    this._animations = {};
    this._setupAnimations();

    this.prevPosition = BABYLON.Vector3.FromArray(position);
    this.move({ position, yRotation });

    this._createShadow();

    this._zeroVelocityCounter = 0;

    this._velocityUpScalar = 0.4;
    this._velocityDownValue = 0.2;
    this._minZeroVelocityCount = 3;

    this._addGUIElements();
  }

  _setupModel(instantiatedModel) {
    this._model = instantiatedModel.rootNodes[0];
    this._model.skeleton = instantiatedModel.skeletons[0];
    this._model.parent = this._container;
    this._model.position.y -= 0.01;

    this._model.isPickable = false;
    this._model.checkCollisions = false;

    this._model.getChildren((child) => {
      if (child.getClassName() === "Mesh") {
        child.isPickable = false;
        child.checkCollisions = false;
        return child;
      }
    }, false);
  }

  _createShadow() {
    this._shadow = BABYLON.MeshBuilder.CreateGround(
      `shadow-${this.participantId}`,
      { width: 1.25, height: 1.25 },
      this._scene
    );
    this._shadow.position = new BABYLON.Vector3(-0.05, 0, 0.06);
    this._shadow.parent = this._container;

    this._shadow.material = new BABYLON.StandardMaterial(
      `shadow-material-${this.participantId}`,
      this._scene
    );
    const texture = new BABYLON.Texture(
      require(`@/assets/images/shadow.png`),
      this._scene
    );
    texture.hasAlpha = true;
    this._shadow.material.diffuseTexture = texture;
    this._shadow.material.useAlphaFromDiffuseTexture = true;
  }

  _setupScreen() {
    this._video.screen = new Screen(
      `screen-${this.participantId}`,
      this._scene,
      this._container,
      {
        noCameraTextureUrl: "images/no_camera.jpg",
        width: 1.08981379,
        height: 0.613020258,
        position: new BABYLON.Vector3(0, 2.15, 0.03),
        rotation: new BABYLON.Vector3(0, Math.PI, 0),
        checkCollisions: false,
      }
    );
    this._video.screen.setVideoPlaceHolder();
  }

  updateAudioMuteState({ stream, isMuted }) {
    if (this._audio.speaker) {
      this._audio.speaker.dispose();
    }

    if (!isMuted && stream) {
      this._audio.speaker = new Speaker(
        this.participantId,
        stream,
        this._scene,
        {
          maxDistance: 12,
        }
      );

      const audioPos = this._container.position.clone();
      audioPos.y += this.speakerYOffset;

      this._audio.speaker.setPosition(audioPos);
    }

    this._audio.isMuted = isMuted;
  }

  updateVideoMuteState({ isMuted, mediaElement }) {
    this._video.mediaElement = mediaElement;
    this._video.isMuted = isMuted;

    if (isMuted) {
      this._video.screen.setVideoPlaceHolder();
    } else {
      this._video.screen.setVideo(this._video.mediaElement);
    }
  }

  removeScreen() {
    if (this._video.screen) {
      this._video.screen.dispose();
      this._video.screen = null;
    }

    this.hasScreen = false;
  }

  _setupAnimations() {
    const idleRange = this._model.skeleton.getAnimationRange("YBot_Idle");
    const walkRange = this._model.skeleton.getAnimationRange("YBot_Walk");
    const runRange = this._model.skeleton.getAnimationRange("YBot_Run");

    this._animations["idle"] = this._scene.beginWeightedAnimation(
      this._model.skeleton,
      idleRange.from,
      idleRange.to,
      1.0,
      true,
      this.speedRatio
    );

    this._animations["walk"] = this._scene.beginWeightedAnimation(
      this._model.skeleton,
      walkRange.from,
      walkRange.to,
      0,
      true,
      this.speedRatio
    );

    this._animations["run"] = this._scene.beginWeightedAnimation(
      this._model.skeleton,
      runRange.from,
      runRange.to,
      0,
      true,
      this.speedRatio
    );
  }

  _addGUIElements() {
    if (!this._gui) return;

    this._playerGUIFolder = this._gui.addFolder(
      `Player: ${this.participantId}`
    );

    this._playerGUIFolder
      .add(this._animations.idle, "weight", 0, 1, 0.001)
      .listen()
      .name("idle weight");

    this._playerGUIFolder
      .add(this._animations.walk, "weight", 0, 1, 0.001)
      .listen()
      .name("walk weight");

    this._playerGUIFolder
      .add(this._animations.run, "weight", 0, 1, 0.001)
      .listen()
      .name("run weight");

    this._playerGUIFolder
      .add(this, "speedRatio", -2, 2, 0.1)
      .listen()
      .onChange(() => {
        this._animations["idle"].speedRatio = this.speedRatio;
        this._animations["walk"].speedRatio = this.speedRatio;
        this._animations["run"].speedRatio = this.speedRatio;
      });

    this._playerGUIFolder
      .add(this, "_velocityUpScalar", 0, 1, 0.001)
      .listen()
      .name("velocityUpScalar");

    this._playerGUIFolder
      .add(this, "_velocityDownValue", 0, 1, 0.001)
      .listen()
      .name("velocityDownValue");

    this._playerGUIFolder
      .add(this, "_minZeroVelocityCount", 0, 10, 1)
      .listen()
      .name("minZeroVelocityCount");
  }

  move({ position, yRotation }) {
    const pos = BABYLON.Vector3.FromArray(position);
    pos.y += this.containerYOffset;

    if (!this._container.position.equals(pos)) {
      this._isDirty = true;
      this._container.position = pos;

      if (this._audio.speaker) {
        const audioPos = this._container.position.clone();
        audioPos.y += this.speakerYOffset;

        this._audio.speaker.setPosition(audioPos);
      }
    }

    this._container.rotation.y = yRotation;
  }

  dispose() {
    if (this._gui && this._playerGUIFolder) {
      this._gui.removeFolder(this._playerGUIFolder);
    }

    this._container.dispose();
  }

  update(deltaTime) {
    if (!this._isDirty) {
      return;
    }

    const distance = BABYLON.Vector3.Distance(
      this.prevPosition,
      this._container.position
    );
    this.prevPosition = this._container.position;

    const velocity = distance / deltaTime;

    if (velocity) {
      this._animations["run"].weight = BABYLON.Scalar.Clamp(
        velocity * this._velocityUpScalar,
        0,
        1
      );

      this._zeroVelocityCounter = 0;
    } else if (!velocity) {
      ++this._zeroVelocityCounter;

      // zeroVelocityCounter fixes problem with velocity equals 0 in some frames even if the model is moving
      if (this._zeroVelocityCounter > this._minZeroVelocityCount) {
        this._animations["run"].weight = BABYLON.Scalar.Clamp(
          this._animations["run"].weight - this._velocityDownValue,
          0,
          1
        );
      }
    }

    this._animations["idle"].weight = 1 - this._animations["run"].weight;

    if (this._animations["idle"].weight === 1) {
      this._isDirty = false;
    }
  }
}
