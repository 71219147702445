import * as BABYLON from "babylonjs";

import SmoothFollow from "../rigged/SmoothFollow.js";
import { ProjectedMaterial } from "@/scene/visual/ProjectedMaterial";
import { constrain, isBetweenOrEquals, round } from "@/scene/utils";

const defaultParams = {
  cameraPosition: new BABYLON.Vector3(0, 0, 12),
  cameraCenterTarget: new BABYLON.Vector3(0, 0, 0),
  x: 0,
  y: 0,
  z: 0,
  warp: false,
  // should be calculated automatically,
  // currently, must be set manually to the specific value to prevent a situation
  // that camera will see a background behind the image
  warpRadius: 1,
  easing: false,
  easeWeight: 0.2,
  imageWidth: 1.0,
  imageHeight: 1.0,
  minZoom: 1,
  maxZoom: 10,
  fov: 0.8,
  imageSideOrientation: BABYLON.Mesh.DOUBLESIDE,
};

export default class ImageProjector {
  constructor(name = "ImageProjector", url, scene, params, gui) {
    this.scene = scene;
    this.gui = gui;
    this.name = name;

    Object.assign(this, defaultParams, params);

    this.smoothing = {
      x: new SmoothFollow(this.x, this.easeWeight),
      y: new SmoothFollow(this.y, this.easeWeight),
      z: new SmoothFollow(this.z, this.easeWeight),
    };

    this.camera = new BABYLON.TargetCamera(
      `${name}-Camera`,
      this.cameraPosition,
      this.scene
    );

    // cameraCenterTarget - vector3 equal to the center of the image and initial target of the camera
    this.camera.target = this.cameraCenterTarget.clone();
    this.cameraZPosWithoutZoomEffect = this.cameraPosition.z;
    this.camera.fov = this.fov;
    this.minZoom += this.cameraCenterTarget.z;
    this.maxZoom += this.cameraCenterTarget.z;

    this._setupProjectedImage(name, url);

    this.projectorMaterial = new ProjectedMaterial(this.scene, this.camera);
    this.projectorMaterial.addRenderedMesh(this.projectedImagePlane);

    this.aspectRatio =
      this.projectorMaterial.renderedTexture.getRenderWidth() /
      this.projectorMaterial.renderedTexture.getRenderHeight();

    this.computeCameraPositionProps();

    this._addGUIElements();
  }

  _addGUIElements() {
    if (!this.gui) return;

    this.guiParams = {
      x: this.cameraPosition.x,
      y: this.cameraPosition.y,
      z: this.cameraPosition.z,
    };

    this.projectorGUIFolder = this.gui.addFolder(
      `Projector Image: ${this.name}`
    );

    this.projectorGUIFolder
      .add(this.guiParams, "x", this.minX, this.maxX)
      .listen()
      .onChange(() => {
        this.setCameraX(this.guiParams.x);
      });

    this.projectorGUIFolder
      .add(this.guiParams, "y", this.minY, this.maxY)
      .listen()
      .onChange(() => {
        this.setCameraY(this.guiParams.y);
      });

    this.projectorGUIFolder
      .add(this.guiParams, "z", this.minZ, this.maxZ)
      .listen()
      .onChange(() => {
        this.setCameraZ(this.guiParams.z);
      });
  }

  setEaseWeight(value) {
    this.smoothing.x.setMass(value);
    this.smoothing.y.setMass(value);
    this.smoothing.z.setMass(value);
  }

  _setupProjectedImage(name, url) {
    this.projectedImagePlane = BABYLON.MeshBuilder.CreatePlane(
      name,
      {
        width: this.imageWidth,
        height: this.imageHeight,
        sideOrientation: this.imageSideOrientation,
      },
      this.scene
    );
    this.projectedImagePlane.position.copyFrom(this.cameraCenterTarget);
    this.projectedImagePlane.material = new BABYLON.StandardMaterial(
      `${name}-Material`,
      this.scene
    );
    this.projectedImagePlane.material.diffuseTexture = new BABYLON.Texture(
      require(`@/assets/${url}`),
      this.scene
    );
    this.projectedImagePlane.material.opacityTexture = this.projectedImagePlane.material.diffuseTexture;
  }

  getProjectorMaterial() {
    return this.projectorMaterial;
  }

  computeCameraPositionProps() {
    this.maxZ = this._getMaxCameraZ();
    this.minZ = this._getMinCameraZ();
    this.distance = this._getCameraDistance();
    this.distanceWithoutZoomEffect = this._getDistanceWithoutZoomEffect();

    this.halfOfProjectionHeight = this._getHalfOfProjectionHeight();
    this.halfOfProjectionWidth = this._getHalfOfProjectionWidth();

    this.halfOfCameraVerticalRange = this._getHalfOfCameraVerticalRange();
    this.halfOfCameraHorizontalRange = this._getHalfOfCameraHorizontalRange();

    this.minX = this._getMinCameraX();
    this.maxX = this._getMaxCameraX();

    this.minY = this._getMinCameraY();
    this.maxY = this._getMaxCameraY();
  }

  setCameraPosition(x, y, z) {
    this.setCameraZ(z);
    this.setCameraY(y);
    this.setCameraX(x);
  }

  setCameraZ(value, zoom = false) {
    const z = constrain(value, this.minZ, this.maxZ);

    this.cameraPosition.z = z;
    this.camera.position.z = z;

    if (!zoom) {
      this.cameraZPosWithoutZoomEffect = z;
    }

    this.computeCameraPositionProps();

    this.setCameraY(this.cameraPosition.y);
    this.setCameraX(this.cameraPosition.x);
  }

  setCameraY(value) {
    const y = constrain(value, this.minY, this.maxY);

    this.cameraPosition.y = y;
    this.camera.position.y = y;
  }

  setCameraX(value) {
    const x = constrain(value, this.minX, this.maxX);

    this.cameraPosition.x = x;
    this.camera.position.x = x;
  }

  setCameraXByXValue(value) {
    // use constrain() instead of throwing error if value is not between -0.5 and 0.5
    // because smoothing.getSmooth() sometimes return value like for example 0.50001
    const x = constrain(value, -0.5, 0.5);

    const dis = this.warp ? this.warpRadius : this.halfOfCameraHorizontalRange;

    const moveByX = dis * x * 2;

    const pos = this.cameraCenterTarget.x + moveByX;

    this.setCameraX(pos);
  }

  setCameraYByYValue(value) {
    // use constrain() instead of throwing error if value is not between -0.5 and 0.5
    // because smoothing.getSmooth() sometimes return value like for example 0.50001
    const y = constrain(value, -0.5, 0.5);

    const dis = this.warp ? this.warpRadius : this.halfOfCameraVerticalRange;

    const moveByY = dis * y * 2;

    const pos = this.cameraCenterTarget.y + moveByY;

    this.setCameraY(pos);
  }

  zoomCamera(value, useFov = true, forceWarp = false, zoomScalar = 0.2) {
    if (this.warp && !forceWarp) return;

    // use constrain() instead of throwing error if value is not between -0.5 and 0.5
    // because smoothing.getSmooth() sometimes return value like for example 0.50001
    const z = constrain(value, -0.5, 0.5);

    const minZoom = Math.max(this.minZ, this.minZoom);
    const maxZoom = Math.min(this.maxZ, this.maxZoom);

    let halfOfCameraDepthRange;
    if (z >= 0) {
      halfOfCameraDepthRange =
        maxZoom - this.cameraCenterTarget.z - this.distanceWithoutZoomEffect;
    } else {
      halfOfCameraDepthRange =
        this.distanceWithoutZoomEffect - (minZoom - this.cameraCenterTarget.z);
    }

    const moveByZ = halfOfCameraDepthRange * (z * 2);

    const pos = this.cameraZPosWithoutZoomEffect + moveByZ;

    if (useFov) {
      this.camera.fov = this.fov + z * (zoomScalar * this.fov);
    }

    this.setCameraZ(pos, true);
  }

  /**
   * Sets x,y,z position and throw error if passed values are not between -0.5 and 0.5
   * @param x - value between -0.5 and 0.5
   * where 0.5 represents maximum swing from the left side of the image
   * and -0.5 represents maximum swing from the right side of the image
   * @param y - value between -0.5 and 0.5
   * where 0.5 represents maximum swing from the top side of the image
   * and -0.5 represents maximum swing from the bottom side of the image
   * @param z - value between -0.5 and 0.5 where 0.5 represents the maximum distance from the projected image or max zoom
   * and -0.5 represents the minimum distance from the projected image or min zoom
   */
  setPosition(x, y, z) {
    if (
      !isBetweenOrEquals(x, -0.5, 0.5) ||
      !isBetweenOrEquals(y, -0.5, 0.5) ||
      !isBetweenOrEquals(z, -0.5, 0.5)
    ) {
      throw new Error("Values are not between -0.5 and 0.5");
    }
    this.x = x;
    this.y = y;
    this.z = z;
  }

  _getHalfOfCameraVerticalRange() {
    return this.imageHeight / 2 - this.halfOfProjectionHeight;
  }

  _getHalfOfCameraHorizontalRange() {
    return this.imageWidth / 2 - this.halfOfProjectionWidth;
  }

  _getDistanceWithoutZoomEffect() {
    return this.cameraZPosWithoutZoomEffect - this.cameraCenterTarget.z;
  }

  _getCameraDistance() {
    return this.cameraPosition.z - this.cameraCenterTarget.z;
  }

  _getMinCameraZ() {
    return this.cameraCenterTarget.z + this.camera.minZ + 0.1;
  }

  _getMaxCameraZ() {
    return (
      this.cameraCenterTarget.z +
      this.imageHeight / 2 / Math.tan(this.camera.fov / 2)
    );
  }

  _getMinCameraX() {
    return (
      this.cameraCenterTarget.x -
      this.imageWidth / 2 +
      this.halfOfProjectionWidth
    );
  }

  _getMaxCameraX() {
    return (
      this.cameraCenterTarget.x +
      this.imageWidth / 2 -
      this.halfOfProjectionWidth
    );
  }

  _getMaxCameraY() {
    return (
      this.cameraCenterTarget.y +
      this.imageHeight / 2 -
      this.halfOfProjectionHeight
    );
  }

  _getMinCameraY() {
    return (
      this.cameraCenterTarget.y -
      this.imageHeight / 2 +
      this.halfOfProjectionHeight
    );
  }

  _getHalfOfProjectionWidth() {
    return (this.aspectRatio * (this.halfOfProjectionHeight * 2)) / 2;
  }

  _getHalfOfProjectionHeight() {
    return Math.tan(this.camera.fov / 2) * this.distance;
  }

  setWarp(value) {
    if (this.warp !== value) {
      let x = this.x;
      let y = this.y;
      let z = this.z;

      if (this.easing) {
        x = this.smoothing.x.getSmooth();
        y = this.smoothing.y.getSmooth();
        z = this.smoothing.z.getSmooth();
      }

      // update warp only when the camera is centered to the image, prevents camera jump
      if (round(x, 2) === 0 && round(y, 2) === 0 && round(z, 2) === 0) {
        this.warp = value;

        if (!this.warp) {
          this.resetTarget();
        }
      }
    }
  }

  update(deltaTime) {
    let x = this.x;
    let y = this.y;
    let z = this.z;

    if (this.easing) {
      this.smoothing.x.set(this.x);
      this.smoothing.y.set(this.y);
      this.smoothing.z.set(this.z);

      this.smoothing.x.loop(deltaTime);
      this.smoothing.y.loop(deltaTime);
      this.smoothing.z.loop(deltaTime);

      x = this.smoothing.x.getSmooth();
      y = this.smoothing.y.getSmooth();
      z = this.smoothing.z.getSmooth();
    }

    this.setCameraXByXValue(x);
    this.setCameraYByYValue(y);
    this.zoomCamera(z);

    if (this.warp) {
      this.camera.target = this.cameraCenterTarget.clone();
    }
  }

  forceSetWarp(value) {
    if (this.warp !== value) {
      this.warp = value;

      this.setPosition(0, 0, 0);

      let x = this.x;
      let y = this.y;
      let z = this.z;

      if (this.easing) {
        this.smoothing.x.set(this.x);
        this.smoothing.y.set(this.y);
        this.smoothing.z.set(this.z);

        this.smoothing.x.forceLoop();
        this.smoothing.y.forceLoop();
        this.smoothing.z.forceLoop();

        x = this.smoothing.x.getSmooth();
        y = this.smoothing.y.getSmooth();
        z = this.smoothing.z.getSmooth();
      }

      this.setCameraXByXValue(x);
      this.setCameraYByYValue(y);
      this.zoomCamera(z, true, true);

      if (!this.warp) {
        this.resetTarget();
      }
    }
  }

  // reset target to prevent the situation that target is not perfectly in the front of the camera
  resetTarget() {
    const target = this.camera.position.clone();
    target.z -= this.distance;
    this.camera.target = target;
  }
}
