import * as BABYLON from "babylonjs";

const defaultParams = {
  height: 9,
  width: 16,
  rotation: new BABYLON.Vector3(),
  position: new BABYLON.Vector3(),
  checkCollisions: true,
  isPickable: false,
  setPositionWithLocalVector: false,
  setAbsolutePosition: false,
  noCameraTextureUrl: null,
};

export class Screen extends BABYLON.TransformNode {
  constructor(name, scene, parent, params) {
    super(name, scene);

    const p = Object.assign(defaultParams, params);

    this.parent = parent;
    this.rotation = p.rotation;
    if (p.setPositionWithLocalVector) {
      this.setPositionWithLocalVector(p.position);
    } else if (p.setAbsolutePosition) {
      this.setAbsolutePosition(p.position);
    } else {
      this.position = p.position;
    }

    this._createVideoPlane(p);
    this._createBackPlane(p);

    this._setPlaceHolderMaterial(p.noCameraTextureUrl);
  }

  _setNoCameraTexture(url) {
    this.noCameraTexture = new BABYLON.Texture(
      require(`@/assets/${url}`),
      this._scene
    );
  }

  _setPlaceHolderMaterial(noCameraTextureUrl) {
    if (!noCameraTextureUrl) {
      throw new Error("Error: noCameraTextureUrl cannot be null");
    }

    this._setNoCameraTexture(noCameraTextureUrl);

    this.placeHolderMaterial = new BABYLON.StandardMaterial(
      "placeHolderMaterial",
      this._scene
    );
    this.placeHolderMaterial.emissiveTexture = this.noCameraTexture;
    this.placeHolderMaterial.disableLighting = true;
  }

  _createVideoPlane({ width, height, checkCollisions, isPickable }) {
    this.videoPlane = BABYLON.MeshBuilder.CreatePlane(
      `videoPlane-${this.name}`,
      {
        height: height,
        width: width,
      },
      this._scene
    );

    this.videoPlane.checkCollisions = checkCollisions;
    this.videoPlane.isPickable = isPickable;
    this.videoPlane.parent = this;
  }

  _createBackPlane({ width, height, checkCollisions, isPickable }) {
    this.backSidePlane = BABYLON.MeshBuilder.CreatePlane(
      `backPlane-${this.name}`,
      {
        height: height,
        width: width,
      },
      this._scene
    );

    this.backSidePlane.checkCollisions = checkCollisions;
    this.backSidePlane.isPickable = isPickable;
    this.backSidePlane.parent = this;

    this.backSidePlane.position.z += 0.005;
    this.backSidePlane.rotate(new BABYLON.Vector3(0, 1, 0), -Math.PI);
  }

  setVideo(videoElement) {
    if (this.videoPlane.material) {
      this.videoPlane.material.dispose();
    }
    this.videoPlane.material = new BABYLON.StandardMaterial(
      "material",
      this._scene
    );
    this.videoPlane.material.diffuseTexture = new BABYLON.VideoTexture(
      "video",
      videoElement,
      this._scene
    );
    this.videoPlane.material.roughness = 1;
    this.videoPlane.material.emissiveColor = new BABYLON.Color3.White();
  }

  setVideoPlaceHolder() {
    if (this.videoPlane.material) {
      this.videoPlane.material.dispose();
    }
    this.videoPlane.material = this.placeHolderMaterial;
  }
}
