export const inspectorMixin = {
  data() {
    return {
      pressedKeys: {},
    };
  },
  methods: {
    keyDown(event) {
      this.pressedKeys[event.key] = true;

      if (this.pressedKeys.i && this.pressedKeys.t && this.scene) {
        if (this.scene.debugLayer.isVisible()) {
          this.scene.debugLayer.hide();
        } else {
          this.scene.debugLayer.show();
        }
      }
    },
    keyUp(event) {
      delete this.pressedKeys[event.key];
    },
    initInspector() {
      document.addEventListener("keydown", this.keyDown);
      document.addEventListener("keyup", this.keyUp);
    },
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.keyDown);
    document.removeEventListener("keyup", this.keyUp);

    if (this.scene) {
      this.scene.debugLayer.hide();
    }
  },
};
