import * as BABYLON from "babylonjs";

export const createPulsingNodeMaterial = () => {
  // generated code from https://nodematerial-editor.babylonjs.com/
  const nodeMaterial = new BABYLON.NodeMaterial("node");

  nodeMaterial._mode = BABYLON.NodeMaterialModes.ProceduralTexture;

  // InputBlock
  const positiond = new BABYLON.InputBlock("position2d");
  positiond.visibleInInspector = false;
  positiond.visibleOnFrame = false;
  positiond.target = 1;
  positiond.setAsAttribute("position2d");

  // VectorMergerBlock
  const PositionD = new BABYLON.VectorMergerBlock("Position3D");
  PositionD.visibleInInspector = false;
  PositionD.visibleOnFrame = false;
  PositionD.target = 4;

  // InputBlock
  const Constant = new BABYLON.InputBlock("Constant1");
  Constant.visibleInInspector = false;
  Constant.visibleOnFrame = false;
  Constant.target = 1;
  Constant.value = 1;
  Constant.min = 0;
  Constant.max = 0;
  Constant.isBoolean = false;
  Constant.matrixMode = 0;
  Constant.animationType = BABYLON.AnimatedInputBlockTypes.None;
  Constant.isConstant = true;

  // VertexOutputBlock
  const VertexOutput = new BABYLON.VertexOutputBlock("VertexOutput");
  VertexOutput.visibleInInspector = false;
  VertexOutput.visibleOnFrame = false;
  VertexOutput.target = 1;

  // LengthBlock
  const Length = new BABYLON.LengthBlock("Length");
  Length.visibleInInspector = false;
  Length.visibleOnFrame = false;
  Length.target = 4;

  // OneMinusBlock
  const Oneminus = new BABYLON.OneMinusBlock("One minus");
  Oneminus.visibleInInspector = false;
  Oneminus.visibleOnFrame = false;
  Oneminus.target = 4;

  // GradientBlock
  const Gradient = new BABYLON.GradientBlock("Gradient");
  Gradient.visibleInInspector = false;
  Gradient.visibleOnFrame = false;
  Gradient.target = 4;
  Gradient.colorSteps = [];
  Gradient.colorSteps.push(
    new BABYLON.GradientBlockColorStep(0, new BABYLON.Color3(0, 0, 0))
  );
  Gradient.colorSteps.push(
    new BABYLON.GradientBlockColorStep(0.25, new BABYLON.Color3(1, 1, 1))
  );

  // MultiplyBlock
  const Multiply = new BABYLON.MultiplyBlock("Multiply");
  Multiply.visibleInInspector = false;
  Multiply.visibleOnFrame = false;
  Multiply.target = 4;

  // InputBlock
  const Color = new BABYLON.InputBlock("Color3");
  Color.visibleInInspector = false;
  Color.visibleOnFrame = false;
  Color.target = 1;
  Color.value = new BABYLON.Color3(1, 1, 1);
  Color.isConstant = false;

  // ScaleBlock
  const Scale = new BABYLON.ScaleBlock("Scale");
  Scale.visibleInInspector = false;
  Scale.visibleOnFrame = false;
  Scale.target = 4;

  // TrigonometryBlock
  const Sin = new BABYLON.TrigonometryBlock("Sin");
  Sin.visibleInInspector = false;
  Sin.visibleOnFrame = false;
  Sin.target = 4;
  Sin.operation = BABYLON.TrigonometryBlockOperations.Sin;

  // MultiplyBlock
  const Multiply1 = new BABYLON.MultiplyBlock("Multiply");
  Multiply1.visibleInInspector = false;
  Multiply1.visibleOnFrame = false;
  Multiply1.target = 4;

  // AddBlock
  const Add = new BABYLON.AddBlock("Add");
  Add.visibleInInspector = false;
  Add.visibleOnFrame = false;
  Add.target = 4;

  // MultiplyBlock
  const Multiply2 = new BABYLON.MultiplyBlock("Multiply");
  Multiply2.visibleInInspector = false;
  Multiply2.visibleOnFrame = false;
  Multiply2.target = 4;

  // InputBlock
  const Time = new BABYLON.InputBlock("Time");
  Time.visibleInInspector = false;
  Time.visibleOnFrame = false;
  Time.target = 1;
  Time.value = 17.091518999994737;
  Time.min = 0;
  Time.max = 0;
  Time.isBoolean = false;
  Time.matrixMode = 0;
  Time.animationType = BABYLON.AnimatedInputBlockTypes.Time;
  Time.isConstant = false;

  // InputBlock
  const Float = new BABYLON.InputBlock("Float");
  Float.visibleInInspector = false;
  Float.visibleOnFrame = false;
  Float.target = 1;
  Float.value = -0.45;
  Float.min = 0;
  Float.max = 0;
  Float.isBoolean = false;
  Float.matrixMode = 0;
  Float.animationType = BABYLON.AnimatedInputBlockTypes.None;
  Float.isConstant = false;

  // InputBlock
  const Float1 = new BABYLON.InputBlock("Float");
  Float1.visibleInInspector = false;
  Float1.visibleOnFrame = false;
  Float1.target = 1;
  Float1.value = 15;
  Float1.min = 0;
  Float1.max = 0;
  Float1.isBoolean = false;
  Float1.matrixMode = 0;
  Float1.animationType = BABYLON.AnimatedInputBlockTypes.None;
  Float1.isConstant = false;

  // FragmentOutputBlock
  const FragmentOutput = new BABYLON.FragmentOutputBlock("FragmentOutput");
  FragmentOutput.visibleInInspector = false;
  FragmentOutput.visibleOnFrame = false;
  FragmentOutput.target = 2;
  FragmentOutput.convertToGammaSpace = undefined;
  FragmentOutput.convertToLinearSpace = undefined;

  // Connections
  positiond.output.connectTo(PositionD.xyIn);
  Constant.output.connectTo(PositionD.w);
  PositionD.xyzw.connectTo(VertexOutput.vector);
  Color.output.connectTo(Multiply.left);
  positiond.output.connectTo(Length.value);
  Length.output.connectTo(Oneminus.input);
  Oneminus.output.connectTo(Gradient.gradient);
  Gradient.output.connectTo(Multiply.right);
  Multiply.output.connectTo(Scale.input);
  Length.output.connectTo(Add.left);
  Time.output.connectTo(Multiply2.left);
  Float.output.connectTo(Multiply2.right);
  Multiply2.output.connectTo(Add.right);
  Add.output.connectTo(Multiply1.left);
  Float1.output.connectTo(Multiply1.right);
  Multiply1.output.connectTo(Sin.input);
  Sin.output.connectTo(Scale.factor);
  Scale.output.connectTo(FragmentOutput.rgb);

  // Output nodes
  nodeMaterial.addOutputNode(VertexOutput);
  nodeMaterial.addOutputNode(FragmentOutput);
  nodeMaterial.build();

  return nodeMaterial;
};
