import * as BABYLON from "babylonjs";
import { setValueByKey } from "@/scene/utils";
import { PointerLockHelper } from "@/scene/PointerLockHelper";
import { EventListener } from "@/scene/EventListener";
import { throttle } from "throttle-debounce";

const defaultParams = {
  checkCollisions: true,
  ellipsoid: new BABYLON.Vector3(0.05, 0.5, 0.05),
  applyGravity: true,
  speed: 0.4,
  angularSensibility: 3600,
  minZ: 0.04,
  maxZ: 1000,
  keysUp: [87],
  keysDown: [83],
  keysRight: [68],
  keysLeft: [65],
  touchMoveSensibility: 200,
  touchAngularSensibility: 4000,
  position: new BABYLON.Vector3(0, 5, 0),
  target: BABYLON.Vector3.Zero(),
  ellipsoidOffset: new BABYLON.Vector3(0, 0, 0),
  gamepadAngularSensibility: 200,
  gamepadMoveSensibility: 40,
  inertia: 0.9,
};

export class FirstPersonViewer extends EventListener {
  static EVT_CAMERA_VIEW_CHANGE = "camera_view_change";

  constructor({ scene, domElement, params = {}, gui }) {
    super();

    this.scene = scene;
    this.domElement = domElement;
    this._gui = gui;

    params = {
      ...defaultParams,
      ...params,
    };

    this.camera = null;
    this._setupCamera(params);

    this.pointerLock = new PointerLockHelper({
      domElement: this.domElement,
      camera: this.camera,
    });

    this._addGUIElements();
  }

  _addGUIElements() {
    if (!this._gui) return;

    this.cameraGUIFolder = this._gui.addFolder("Camera");
    this.cameraGUIFolder.add(this.camera, "touchMoveSensibility", 1, 10000);
    this.cameraGUIFolder.add(this.camera, "touchAngularSensibility", 1, 10000);
    this.cameraGUIFolder.add(this.camera, "angularSensibility", 1, 10000);
    this.cameraGUIFolder.add(this.camera, "gamepadAngularSensibility", 1, 2000);
    this.cameraGUIFolder.add(this.camera, "gamepadMoveSensibility", 1, 2000);
    this.cameraGUIFolder.add(this.camera, "inertia", 0, 1);
    this.cameraGUIFolder.add(this.camera, "speed", 0, 1);
  }

  _setupCamera(params) {
    this.camera = new BABYLON.UniversalCamera(
      "viewerCamera",
      new BABYLON.Vector3(0, 1, 0),
      this.scene
    );

    this.camera.onViewMatrixChangedObservable.add(
      throttle(30, (eventData) => {
        const params = {
          position: eventData.position.asArray(),
          yRotation: eventData.rotation.y,
        };
        this._emit(FirstPersonViewer.EVT_CAMERA_VIEW_CHANGE, params);
      })
    );

    this.setCameraParams(params);
  }

  _createMouseWheelInput({ precisionX, precisionY, precisionZ }) {
    this.mouseWheelInput = new BABYLON.FreeCameraMouseWheelInput();
    this.mouseWheelInput.wheelPrecisionX = precisionX;
    this.mouseWheelInput.wheelPrecisionY = precisionY;
    this.mouseWheelInput.wheelPrecisionZ = precisionZ;
  }

  setCameraParams(params) {
    setValueByKey(this.camera, params);
  }

  addMouseWheelInput({
    precisionX = 0.5,
    precisionY = 0.5,
    precisionZ = 0.5,
    setGUI,
  }) {
    if (this.mouseWheelInput) {
      throw new Error("MouseWheelInput is already defined");
    }

    this._createMouseWheelInput({ precisionX, precisionY, precisionZ });

    this.camera.inputs.add(this.mouseWheelInput);

    if (this.gui && setGUI) {
      this.cameraGUIFolder.add(
        this.mouseWheelInput,
        "wheelPrecisionX",
        0,
        4,
        0.1
      );
      this.cameraGUIFolder.add(
        this.mouseWheelInput,
        "wheelPrecisionY",
        0,
        4,
        0.1
      );
      this.cameraGUIFolder.add(
        this.mouseWheelInput,
        "wheelPrecisionZ",
        0,
        4,
        0.1
      );
    }
  }

  assign() {
    this.pointerLock.assignPointerLock();
  }

  dispose() {
    this.pointerLock.disposePointerLock();
    this.camera.disable();
  }
}
