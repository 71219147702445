export class EventListener {
  constructor() {
    this._listeners = [];
  }

  addEventListener (name, handler) {
    this._listeners.push({ name, handler });
  }

  removeEventListener (name, handler) {
    this._listeners = this._listeners.filter(l => l.name !== name || l.handler !== handler);
  }

  _emit (name, ...args) {
    this._listeners.filter(l => l.name === name)
      .forEach(e => e.handler(...args));
  }
}
