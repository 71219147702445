import BoneAnimation from "@/scene/rigged/BoneAnimation";

const defaultParams = {
  precision: 0.1,
};

export class BoneAnimationParent {
  constructor(model, bones, params) {
    this.boneAnimations = [];
    this.model = model;

    Object.assign(this, defaultParams, params);

    this._init(bones);
  }

  _init(bones) {
    bones.forEach((bone) => {
      try {
        this.boneAnimations.push(new BoneAnimation(this.model, bone));
      } catch (e) {
        console.error(e);
      }
    });
  }

  setMass(mass) {
    this.boneAnimations.forEach((animation) => animation.setMass(mass));
  }

  setPos(x, y, z) {
    this.boneAnimations.forEach((animation) => animation.setPos(x, y, z));
  }

  loop(delta) {
    this.boneAnimations.forEach((animation) => animation.loop(delta));

    this.listenForOnComplete();
  }

  listenForOnComplete() {
    if (this.onComplete && this.isCompleted(this.precision)) {
      this.boneAnimations.forEach((el) => el.isCompleted(this.precision));
      this.onComplete();
      this.onComplete = null;
    }
  }

  isCompleted(precision = 0.1) {
    return this.boneAnimations.every(
      (animation) => animation.isCompleted(precision) === true
    );
  }

  forceLoopUpdate() {
    this.boneAnimations.forEach((animation) => animation.forceLoopUpdate());
  }
}
