<template>
  <transition name="fade-out">
    <div class="lobby-overlay" v-if="show">
      <div class="lobby-overlay__content">
        <button @click="enter">Enter</button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'LobbyOverlay',
  data() {
    return {
      show: true,
    };
  },
  methods: {
    enter() {
      if(!this.show) {
        return;
      }
      this.show = false;
      this.$emit('enter');
    },
  },
};
</script>

<style lang="scss" scoped>
  .lobby-overlay {
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &__content {
      button {
        background: transparent;
        border: 1px solid white;
        color: white;
        cursor: pointer;
        border-radius: 8px;
        padding: 15px 30px;
        text-transform: uppercase;
        outline: none;

        &:disabled {
          opacity: 0.6;
        }
      }
    }
  }

  .fade-out-leave-active {
    transition: opacity .5s;
  }
  .fade-out-leave-to {
    opacity: 0;
  }
</style>