import * as BABYLON from "babylonjs";

export class Speaker extends BABYLON.Sound {
  constructor(name, stream, scene, params) {
    super(name, stream, scene, null, {
      autoplay: true,
      streaming: true,
      loop: true,
      spatialSound: true,
      maxDistance: 10,
      ...params,
    });
  }
}
