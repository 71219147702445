import * as BABYLON from "babylonjs";

export const basicSceneOptimizer = (
  targetFrameRate = 60,
  trackerDuration = 2000
) => {
  const sceneOptimizerOptions = new BABYLON.SceneOptimizerOptions(
    targetFrameRate,
    trackerDuration
  );

  sceneOptimizerOptions.addOptimization(new BABYLON.ShadowsOptimization(0));

  sceneOptimizerOptions.addOptimization(
    new BABYLON.PostProcessesOptimization(0)
  );

  sceneOptimizerOptions.addOptimization(new BABYLON.LensFlaresOptimization(0));

  sceneOptimizerOptions.addOptimization(new BABYLON.ParticlesOptimization(0));

  sceneOptimizerOptions.addOptimization(
    new BABYLON.TextureOptimization(1, 512)
  );

  sceneOptimizerOptions.addOptimization(
    new BABYLON.HardwareScalingOptimization(2, 1)
  );

  sceneOptimizerOptions.addOptimization(
    new BABYLON.HardwareScalingOptimization(2, 2)
  );

  return sceneOptimizerOptions;
};
