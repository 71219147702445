import * as BABYLON from "babylonjs";
import { getObjectByName } from "@/scene/utils";
import ImageProjector from "@/scene/visual/ImageProjector";
import TWEEN from "@tweenjs/tween.js";
import { pointLockFeature } from "@/scene/kinevo/KinevoController";

const params = {
  leftScreenOverlayPosition: new BABYLON.Vector3(19.52, 1.544, 0.397),
  leftScreenImagePosition: new BABYLON.Vector3(19.504, 1.535, 0.399),
  rightScreenPosition: new BABYLON.Vector3(20.241, 1.544, 0.144),
  kinevoScreensNodeName: "KINEVO_SCREENS",
  defaultScreenColor: "#303030",
  screenWidth: 16,
  screenHeight: 9,
  screenSideOrientation: BABYLON.Mesh.DOUBLESIDE,
  screenLeftImageScale: 0.0305,
  screenLeftOverlayScale: 0.0325,
  screenRightScale: 0.0325,
  overlayUrl: "images/kinevo-overlay-1024px.png",
  projectedImageUrl: "images/tumor_1024px_desaturated.jpg",
};

export class KinevoScreenHelper {
  constructor({ kinevoModel, scene, gui, container }) {
    this.scene = scene;
    this.gui = gui;
    this.container = container;

    this._setupScreens(kinevoModel);

    this.screenAlpha = 0;

    this._setupDefaultScreens();
    this._setupProjectedImage();
    this._setupLeftScreen();
    this._setupRightScreen();
  }

  _setupScreens(kinevoModel) {
    this.kinevoScreens = getObjectByName(
      kinevoModel,
      params.kinevoScreensNodeName
    );

    const children = this.kinevoScreens.getChildren();

    this.leftScreen = children[0];
    this.rightScreen = children[1];
  }

  _setupDefaultScreens() {
    const defaultScreenColor = new BABYLON.Color3.FromHexString(
      params.defaultScreenColor
    );

    this.rightScreen.material.dispose();
    this.rightScreen.material = new BABYLON.StandardMaterial(
      "rightScreenMaterial",
      this.scene
    );
    this.rightScreen.material.diffuseColor = defaultScreenColor;

    this.leftScreen.material.dispose();
    this.leftScreen.material = new BABYLON.StandardMaterial(
      "leftScreenMaterial",
      this.scene
    );
    this.leftScreen.material.diffuseColor = defaultScreenColor;
  }

  _setupProjectedImage() {
    const cameraCenterTarget = new BABYLON.Vector3();
    const cameraPosition = cameraCenterTarget.clone();
    cameraPosition.z += 5;

    this.projectedImage = new ImageProjector(
      "kinevoProjectedImage",
      params.projectedImageUrl,
      this.scene,
      {
        imageHeight: 9,
        imageWidth: 16,
        minZoom: 3,
        maxZoom: 7,
        warpRadius: 2.15,
        cameraPosition: cameraPosition,
        cameraCenterTarget: cameraCenterTarget,
        easing: true,
      },
      this.gui
    );
  }

  _setupLeftScreen() {
    this.projectedLeftScreen = BABYLON.MeshBuilder.CreatePlane(
      "projectedLeftScreen",
      {
        width: params.screenWidth,
        height: params.screenHeight,
        sideOrientation: params.screenSideOrientation,
      },
      this.scene
    );

    this.projectedLeftScreen.rotation.y = 0.121;
    this.projectedLeftScreen.parent = this.container;

    this.projectedLeftScreenOverlay = this.projectedLeftScreen.clone();

    this.projectedLeftScreen.material = this.projectedImage.getProjectorMaterial();
    this.projectedLeftScreen.material.alpha = 0;

    this.projectedLeftScreen.scaling.scaleInPlace(params.screenLeftImageScale);
    this.projectedLeftScreenOverlay.scaling.scaleInPlace(
      params.screenLeftOverlayScale
    );

    this.projectedLeftScreen.position.copyFrom(params.leftScreenImagePosition);
    this.projectedLeftScreenOverlay.position.copyFrom(
      params.leftScreenOverlayPosition
    );

    this.projectedLeftScreenOverlay.position.z += 0.0001;
    this.projectedLeftScreenOverlay.material = new BABYLON.StandardMaterial(
      "kinevoLeftScreenOverlay",
      this.scene
    );
    this.projectedLeftScreenOverlay.material.diffuseTexture = new BABYLON.Texture(
      require(`@/assets/${params.overlayUrl}`),
      this.scene
    );
    this.projectedLeftScreenOverlay.material.diffuseTexture.hasAlpha = true;
    this.projectedLeftScreenOverlay.material.opacityTexture = this.projectedLeftScreenOverlay.material.diffuseTexture;
    this.projectedLeftScreenOverlay.material.alpha = 0;
  }

  _setupRightScreen() {
    this.projectedRightScreen = BABYLON.MeshBuilder.CreatePlane(
      "projectedRightScreen",
      {
        width: params.screenWidth,
        height: params.screenHeight,
        sideOrientation: params.screenSideOrientation,
      },
      this.scene
    );

    this.projectedRightScreen.position.copyFrom(params.rightScreenPosition);
    this.projectedRightScreen.rotation.y = 0.508;
    this.projectedRightScreen.scaling.scaleInPlace(params.screenRightScale);
    this.projectedRightScreen.parent = this.container;

    this.projectedRightScreen.material = this.projectedImage.getProjectorMaterial();

    this.projectedRightScreen.material.alpha = 0;
  }

  updateProjection({ x, z, y, feature }, delta) {
    if (this.projectedLeftScreen) {
      this.projectedImage.setWarp(feature === pointLockFeature);

      const xPos = 0.5 - x;
      const yPos = z - 0.5;
      const zPos = this.projectedImage.warp ? 0 : y - 0.5;

      this.projectedImage.setPosition(xPos, yPos, zPos);
      this.projectedImage.update(delta);
    }
  }

  animateScreens(targetAlpha, params) {
    const p = {
      duration: 500,
      easing: TWEEN.Easing.Cubic.InOut,
      onComplete: () => {},
      ...params,
    };

    if (this.screenTween) this.screenTween.stop();

    this.screenTween = new TWEEN.Tween({ alpha: this.screenAlpha })
      .to({ alpha: targetAlpha }, p.duration)
      .easing(p.easing)
      .onUpdate((v) => {
        this.screenAlpha = v.alpha;
        this.projectedLeftScreen.material.alpha = v.alpha;
        this.projectedLeftScreenOverlay.material.alpha = v.alpha;
        this.projectedRightScreen.material.alpha = v.alpha;

        p.onComplete();
      })
      .start();
  }

  turnOff() {
    this.animateScreens(0);
  }

  turnOn() {
    this.animateScreens(1);
  }

  setMass(value) {
    this.projectedImage.setEaseWeight(value);
  }

  forceProjectionUpdate(warp) {
    this.projectedImage.forceSetWarp(warp);
  }
}
