<template>
  <div class="meet-controls__wrapper" v-if="showControls">
    <div class="meet-controls__buttons">
      <button @click="toggleAudio" :class="{ muted: audioMuted }" :disabled="disableAudioButton">
        <MicOffIcon v-if="audioMuted" />
        <MicIcon v-else />
      </button>
      <button @click="toggleVideo" :class="{ muted: videoMuted }" :disabled="disableVideoButton">
        <VideoOffIcon v-if="videoMuted" />
        <VideoIcon v-else />
      </button>
    </div>
  </div>
</template>

<script>
import MicIcon from 'vue-material-design-icons/Microphone';
import MicOffIcon from 'vue-material-design-icons/MicrophoneOff';
import VideoIcon from 'vue-material-design-icons/Video';
import VideoOffIcon from 'vue-material-design-icons/VideoOff';
import { JitsiMeet } from '@/meet/JitsiMeet';

export default {
  name: 'MeetControls',
  components: {
    MicIcon,
    MicOffIcon,
    VideoIcon,
    VideoOffIcon,
  },
  props: {
    jitsi: {
      type: JitsiMeet,
      required: true,
    },
  },
  data() {
    return {
      showControls: false,
      audioMuted: false,
      videoMuted: false,
      disableAudioButton: false,
      disableVideoButton: false,
    };
  },
  methods: {
    async toggleAudio() {
      this.disableAudioButton = true;
      if (this.audioMuted) {
        await this.jitsi.unmuteAudio();
      } else {
        await this.jitsi.muteAudio();
      }

      this.disableAudioButton = false;
    },
    async toggleVideo() {
      this.disableVideoButton = true;
      if (this.videoMuted) {
        await this.jitsi.unmuteVideo();
      } else {
        await this.jitsi.muteVideo();
      }

      this.disableVideoButton = false;
    },
  },
  mounted() {
    this.jitsi.addEventListener(JitsiMeet.EVT_CONFERENCE_JOINED, () => {
      this.audioMuted = this.jitsi.isAudioMuted();
      this.videoMuted = this.jitsi.isVideoMuted();
      this.showControls = true;
    });
    this.jitsi.addEventListener(JitsiMeet.EVT_AUDIO_MUTED, (data) => {
      if (data.isLocal) {
        this.audioMuted = data.isMuted;
      }
    });
    this.jitsi.addEventListener(JitsiMeet.EVT_VIDEO_MUTED, (data) => {
      if (data.isLocal) {
        this.videoMuted = data.isMuted;
      }
    })
  }
};
</script>

<style lang="scss" scoped>
 .meet-controls {
   &__wrapper {
     position: absolute;
     bottom: 10px;
     left: 0;
     right: 0;
   }

   &__buttons {
     display: flex;
     flex-direction: row;
     justify-content: center;

     button {
       border-radius: 50%;
       width: 50px;
       height: 50px;
       background-color: white;
       border: 1px solid #888;
       margin-right: 10px;
       cursor: pointer;
       outline: none;

       &:hover {
         filter: brightness(85%);
       }

       &.muted {
         border: none;
         background-color: #ff3838;
       }
     }
   }
 }
</style>