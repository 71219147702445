import { Player } from "@/scene/player/Player";

const defaultParams = {
  defaultPositionArray: [0, 0, 0],
  defaultYRotation: Math.PI,
};

export class PlayerHelper {
  onLocalPlayerUpdate = () => {};
  onPlayerDisconnect = () => {};

  constructor({ modelContainer, scene, params, gui }) {
    this._modelContainer = modelContainer;
    this._scene = scene;
    this._participantId = null;
    this._players = {};
    this._gui = gui;

    Object.assign(this, defaultParams, params);
  }

  addPlayer(participantId, { position, yRotation }) {
    this._players[participantId] = new Player({
      participantId,
      position,
      yRotation,
      modelContainer: this._modelContainer,
      scene: this._scene,
      params: {
        containerYOffset: -1.558,
      },
      gui: this._gui,
    });
  }

  removePlayerByParticipantId(participantId) {
    if (!this._players[participantId]) {
      throw new Error("No such participant");
    }
    this._players[participantId].dispose();
    delete this._players[participantId];
  }

  addLocalPlayer(participantId) {
    this._participantId = participantId;

    this.onLocalPlayerUpdate(this._participantId, {
      position: this.defaultPositionArray,
      yRotation: this.defaultYRotation,
    });
  }

  movePlayerByParticipantId(participantId, { position, yRotation }) {
    if (!this._players[participantId]) {
      this.addPlayer(participantId, { position, yRotation });
      return;
    }

    this._players[participantId].move({ position, yRotation });
  }

  moveLocalPlayer({ position, yRotation }) {
    if (!this._participantId) {
      return;
    }

    this.onLocalPlayerUpdate(this._participantId, {
      position,
      yRotation,
    });
  }

  setOnLocalPlayerUpdate(onLocalPlayerUpdate) {
    this.onLocalPlayerUpdate = onLocalPlayerUpdate;
  }

  setOnPlayerDisconnect(onPlayerDisconnect) {
    this.onPlayerDisconnect = onPlayerDisconnect;
  }

  dispose() {
    this.onPlayerDisconnect(this._participantId);
  }

  updateMuteState(
    { participantId, isMuted, mediaElement, type },
    createIfNotExists = true
  ) {
    if (!this._players[participantId]) {
      if (createIfNotExists) {
        this.addPlayer(participantId, {
          position: this.defaultPositionArray,
          yRotation: this.defaultYRotation,
        });

        if (type === "audio") {
          this._players[participantId].updateAudioMuteState({
            isMuted,
            stream: mediaElement.srcObject,
          });
        } else {
          this._players[participantId].updateVideoMuteState({
            isMuted,
            mediaElement,
          });
        }
      } else {
        throw new Error(`No such participant: ${participantId}`);
      }
    } else {
      if (type === "audio") {
        this._players[participantId].updateAudioMuteState({
          isMuted,
          stream: mediaElement.srcObject,
        });
      } else {
        this._players[participantId].updateVideoMuteState({
          isMuted,
          mediaElement,
        });
      }
    }
  }

  updatePlayers(deltaTime) {
    Object.values(this._players).forEach((player) => player.update(deltaTime));
  }
}
