<template>
  <div id="dot"></div>
</template>

<script>
export default {
  name: "Dot",
};
</script>

<style lang="scss" scoped>
#dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 7px;
  height: 7px;
  background-color: #000;
  border-radius: 7px;
  opacity: 0.4;
  border: 2px solid #fff;
}
</style>
