class PageVisibilityObserver {
  constructor() {
    this.isHidden = false;

    this.hidden = null;
    this.visibilityChange = null;

    this.onHideEvents = [];
    this.onShowEvents = [];

    this._setProperties();

    this.assign();
  }

  _setProperties() {
    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      this.hidden = "hidden";
      this.visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      this.hidden = "msHidden";
      this.visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      this.hidden = "webkitHidden";
      this.visibilityChange = "webkitvisibilitychange";
    }
  }

  assign() {
    document.addEventListener(this.visibilityChange, this.onVisibilityChange);
  }

  dispose() {
    document.removeEventListener(
      this.visibilityChange,
      this.onVisibilityChange
    );
  }

  onVisibilityChange = () => {
    if (document[this.hidden]) {
      this.isHidden = true;

      this.onHideEvents.forEach((func) => {
        func();
      });
    } else {
      this.isHidden = false;

      this.onShowEvents.forEach((func) => {
        func();
      });
    }
  };

  addOnHideEvent(func) {
    if (typeof func !== "function") {
      throw new Error("The parameter must be a function.");
    }

    this.onHideEvents.push(func);
  }

  removeOnHideEvent(func) {
    if (typeof func !== "function") {
      throw new Error("The parameter must be a function.");
    }

    const idx = this.onHideEvents.indexOf(func);
    if (idx > -1) {
      this.onHideEvents.splice(idx, 1);
    }
  }

  addOnShowEvent(func) {
    if (typeof func !== "function") {
      throw new Error("The parameter must be a function.");
    }

    this.onShowEvents.push(func);
  }

  removeOnShowEvent(func) {
    if (typeof func !== "function") {
      throw new Error("The parameter must be a function.");
    }

    const idx = this.onShowEvents.indexOf(func);
    if (idx > -1) {
      this.onShowEvents.splice(idx, 1);
    }
  }
}

export default new PageVisibilityObserver();
