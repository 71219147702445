import * as BABYLON from "babylonjs";
import { ProjectedTexture } from "@/scene/visual/ProjectedTexture";

export class ProjectedMaterial extends BABYLON.StandardMaterial {
  constructor(scene, camera, params) {
    super("projectedMaterial", scene);

    this.renderedTexture = new ProjectedTexture(scene, camera, params);
    scene.customRenderTargets.push(this.renderedTexture);

    this.diffuseColor = new BABYLON.Color3(0, 0, 0);
    this.specularColor = new BABYLON.Color3(0, 0, 0);
    this.emissiveTexture = this.renderedTexture;
  }

  render() {
    this.renderedTexture.render();
  }

  addRenderedMesh(mesh, setMask = true) {
    this.renderedTexture.addRenderedMesh(mesh, setMask);
  }

  removeRendererMesh(mesh) {
    this.renderedTexture.removeRendererMesh(mesh);
  }
}
