<template>
  <div ref="scene" class="scene">
    <canvas id="canvas" ref="canvas"></canvas>
    <Dot />
    <MeetControls v-if="jitsi" :jitsi="jitsi" />
    <LobbyOverlay @enter="enter" />
    <KinevoPanel v-if="isEntered" />
    <InteractionIndicator v-if="isEntered" :isVisible="isIndicatorVisible" />
  </div>
</template>
<script>
import * as BABYLON from "babylonjs";
import "babylonjs-loaders";
import { FirstPersonViewer } from "@/scene/FirstPersonViewer";
import { gui as dat } from "dat.gui";
import KinevoController from "@/scene/kinevo/KinevoController";
import Dot from "@/components/commons/Dot";
import TWEEN from "@tweenjs/tween.js";
import Stats from "stats.js";
import { JitsiMeet, LOCAL_USER_ID } from "@/meet/JitsiMeet";
import { SceneInteractionSynchronizer } from "@/meet/SceneInteractionSynchronizer";
import MeetControls from "@/components/commons/MeetControls";
import { getObjectByName } from "@/scene/utils";
import LobbyOverlay from "@/components/commons/LobbyOverlay";
import KinevoPanel from "@/components/kinevo/KinevoPanel";
import InteractionIndicator from "@/components/commons/InteractionIndicator";
import { ProceduralTexturePointerHelper } from "@/scene/pointers/ProceduralTexturePointerHelper";
import { PlayerHelper } from "@/scene/player/PlayerHelper";
import { inspectorMixin } from "@/mixins/inspectorMixin";
import { basicSceneOptimizer } from "@/scene/optimizer/basicSceneOptimizer";

export default {
  name: "Viewer",
  components: {
    InteractionIndicator,
    KinevoPanel,
    LobbyOverlay,
    MeetControls,
    Dot,
  },
  mixins: [inspectorMixin],
  props: {
    confName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      engine: null,
      scene: null,
      onWindowResize: null,
      firstPersonViewer: null,
      gui: null,
      kinevoController: null,
      stats: null,
      jitsi: null,
      sceneOptimizer: null,
      isEntered: false,
    };
  },
  methods: {
    loadScene() {
      Promise.all([
        BABYLON.SceneLoader.ImportMeshAsync(
          null,
          require("@/assets/models/201014_TUMOR_WF_Room_DBE_v026.glb"),
          "",
          this.scene
        ),
        BABYLON.SceneLoader.ImportMeshAsync(
          null,
          require("@/assets/models/201103_TUMOR_WF_Kinevo_DBE_v038.glb"),
          "",
          this.scene
        ),
        BABYLON.SceneLoader.ImportMeshAsync(
          null,
          require("@/assets/models/201029_TUMOR_WF_Patient_DBE_v034.glb"),
          "",
          this.scene
        ),
        BABYLON.SceneLoader.ImportMeshAsync(
          null,
          require("@/assets/models/collision-scene.glb"),
          "",
          this.scene
        ),
        BABYLON.SceneLoader.ImportMeshAsync(
          null,
          require("@/assets/models/collision-kinevo.glb"),
          "",
          this.scene
        ),
        BABYLON.SceneLoader.ImportMeshAsync(
          null,
          require("@/assets/models/ZVS_New_Setup_v03_Animation_See_Static_Cursor.glb"),
          "",
          this.scene
        ),
        BABYLON.SceneLoader.LoadAssetContainerAsync(
          require("@/assets/models/player/dummy3.babylon"),
          "",
          this.scene
        ),
      ]).then((model) => {
        // optimize room
        const room = model[0].meshes[0];
        room.getChildren(null, false).forEach((child) => {
          if (child.getClassName() === "Mesh") {
            if (
              [
                "Installations-Installation",
                "Installations-Wall_Installation",
                "Room.1-B_Room",
                "Room.1-Emission",
                "Wall_01",
                "Wall_02",
                "Wall_03",
                "Wall_04",
              ].includes(child.name)
            ) {
              child.cullingStrategy =
                BABYLON.AbstractMesh.CULLINGSTRATEGY_OPTIMISTIC_INCLUSION_THEN_BSPHERE_ONLY;
            }

            child.freezeWorldMatrix();
            child.doNotSyncBoundingInfo = true;
          }

          if (child.material) {
            child.material.backFaceCulling = true;
            child.material.sideOrientation = BABYLON.Mesh.FRONTSIDE;
            child.material.freeze();
          }
        });

        // use collision scene to improve fps
        const collisionScene = getObjectByName(
          model[3].meshes[0],
          "Collision_Group"
        );
        collisionScene.getChildren().forEach((el) => {
          el.checkCollisions = true;
          el.visibility = 0;

          // optimize collision scene
          if (el.getClassName() === "Mesh") {
            el.freezeWorldMatrix();
            el.doNotSyncBoundingInfo = true;
            el.cullingStrategy =
              BABYLON.AbstractMesh.CULLINGSTRATEGY_OPTIMISTIC_INCLUSION_THEN_BSPHERE_ONLY;
          }
        }, false);

        const kinevoModel = model[1].meshes[0];
        kinevoModel.getChildren(null, false).forEach((child) => {
          if (child.material) {
            child.material.backFaceCulling = true;
            child.material.sideOrientation = BABYLON.Mesh.FRONTSIDE;
          }
        });

        const humanModel = model[2].meshes[0];

        const kinevoCollider = getObjectByName(
          model[4].meshes[0],
          "Kinevo_Collision_Group"
        );

        const arrowModel = getObjectByName(model[5].meshes[0], "See_Pfeil_02");

        this.kinevoController = new KinevoController({
          gui: this.gui,
          scene: this.scene,
          camera: this.firstPersonViewer.camera,
          domElement: this.$refs.canvas,
          pointerLock: this.firstPersonViewer.pointerLock,
          kinevoModel,
          humanModel,
          kinevoCollider,
          arrowModel,
        });
        this.kinevoController.assignInteraction();
        this.kinevoController.refreshKinevoBoundingBoxes();

        const modelContainer = model[6];

        this.playerHelper = new PlayerHelper({
          modelContainer,
          params: { defaultPositionArray: [-20, 1.61, 7.68] },
          scene: this.scene,
          gui: this.gui,
        });

        this.firstPersonViewer.addEventListener(
          FirstPersonViewer.EVT_CAMERA_VIEW_CHANGE,
          (params) => {
            this.playerHelper.moveLocalPlayer(params);
          }
        );

        this.firstPersonViewer.assign();

        this.defaultLoadingScreen.hideLoadingUI();

        BABYLON.SceneOptimizer.OptimizeAsync(
          this.scene,
          basicSceneOptimizer(40, 5000),
          () => {
            console.log("Successfully optimized scene");
          },
          () => {
            console.log("FPS target not reached");
          }
        );
      });
    },
    enter() {
      this.initConference();
      this.isEntered = true;
      this.initInspector();
    },
    initConference() {
      if (this.confName) {
        this.jitsi.connect().then(() => {
          this.jitsi.join(this.confName);
          this.initSceneSynchronizer();
        });
      }
    },
    initSceneSynchronizer() {
      this.sceneSynchronizer = new SceneInteractionSynchronizer(
        this.confName,
        this.pointerHelper,
        this.kinevoController,
        this.playerHelper
      );
    },
  },
  computed: {
    isIndicatorVisible() {
      return (
        this.kinevoController &&
        this.kinevoController.isBlockedByOtherParticipant()
      );
    },
  },
  created() {
    window.addEventListener("beforeunload", () => {
      if (this.playerHelper) {
        this.playerHelper.dispose();
      }
    });
  },
  mounted() {
    this.jitsi = new JitsiMeet({ attachAudio: false });
    this.jitsi.addEventListener(JitsiMeet.EVT_PARTICIPANT_JOINED, (data) => {
      console.log("Participant added", data);

      if (data.participantId === LOCAL_USER_ID) {
        const localUserId = this.jitsi.getUserId();
        this.kinevoController.setParticipantId(localUserId);
        this.playerHelper.addLocalPlayer(localUserId);
      }
    });
    this.jitsi.addEventListener(JitsiMeet.EVT_VIDEO_MUTED, (data) => {
      console.log("Participant video (un)muted", data);

      if (data.participantId !== LOCAL_USER_ID) {
        this.playerHelper.updateMuteState(data);
      }
    });

    this.jitsi.addEventListener(JitsiMeet.EVT_AUDIO_MUTED, (data) => {
      console.log("Participant audio (un)muted", data);

      if (data.participantId !== LOCAL_USER_ID) {
        this.playerHelper.updateMuteState(data);
      }
    });

    this.defaultLoadingScreen = new BABYLON.DefaultLoadingScreen(
      this.$refs.canvas
    );
    this.defaultLoadingScreen.displayLoadingUI();

    this.engine = new BABYLON.Engine(
      this.$refs.canvas,
      true,
      {
        preserveDrawingBuffer: true,
        stencil: true,
      },
      true
    );

    this.scene = new BABYLON.Scene(this.engine, {
      useGeometryIdsMap: true,
      useMaterialMeshMap: true,
      useClonedMeshMap: true,
    });
    this.scene.clearColor = new BABYLON.Color3(217 / 255, 234 / 255, 255 / 255);

    this.scene.gravity = new BABYLON.Vector3(0, -0.9, 0);
    this.scene.collisionsEnabled = true;

    this.stats = new Stats();
    document.body.appendChild(this.stats.dom);

    this.gui = new dat.GUI();
    this.gui.domElement.style.zIndex = "1000";
    this.gui.domElement.style.position = "absolute";
    this.gui.domElement.style.right = "290px";
    this.gui.domElement.style.top = "0";
    this.$refs.scene.appendChild(this.gui.domElement);
    this.gui.close();

    this.firstPersonViewer = new FirstPersonViewer({
      scene: this.scene,
      domElement: this.$refs.canvas,
      params: {
        ellipsoid: new BABYLON.Vector3(0.4, 1.2, 0.4),
        position: new BABYLON.Vector3(-20, 1.69, 7.68),
        target: new BABYLON.Vector3(-20, 1.69, 0),
        ellipsoidOffset: new BABYLON.Vector3(0, 0.73, 0),
        minZ: 0.3,
        speed: 0.6,
        angularSensibility: 1500,
        inertia: 0.6,
      },
      gui: this.gui,
    });
    this.firstPersonViewer.addMouseWheelInput({
      precisionX: 0,
      precisionY: 0.15,
      precisionZ: 0,
      setGUI: true,
    });

    this.light = new BABYLON.HemisphericLight(
      "mainLight",
      new BABYLON.Vector3(0, 1, 0),
      this.scene
    );

    this.loadScene();

    this.pointerHelper = new ProceduralTexturePointerHelper({
      scene: this.scene,
      domElement: this.$refs.canvas,
      url: "images/pointer-example.png",
      params: {
        width: 0.07,
        height: 0.07,
        translateYOffset: 0.01,
        duration: 8000,
      },
      gui: this.gui,
    });

    this.engine.runRenderLoop(() => {
      this.scene.render();

      const deltaTime = this.engine.getDeltaTime() / 1000;

      TWEEN.update();

      this.stats.update();

      if (this.playerHelper) {
        this.playerHelper.updatePlayers(deltaTime);
      }

      if (this.kinevoController) this.kinevoController.update(deltaTime);
    });

    this.onWindowResize = () => {
      this.engine.resize();
    };

    window.addEventListener("resize", this.onWindowResize);
  },
  beforeDestroy() {
    this.jitsi.leave();
    if (this.playerHelper) {
      this.playerHelper.dispose();
    }
    if (this.sceneSynchronizer) {
      this.sceneSynchronizer.close();
    }
    window.removeEventListener("resize", this.onWindowResize);
  },
};
</script>

<style lang="scss" scoped>
#canvas {
  width: 100%;
  height: 100%;
  outline: none;
}

.scene {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
