import { IntervalPointerHelper } from "@/scene/pointers/IntervalPointerHelper";
import * as BABYLON from "babylonjs";
import { createPulsingNodeMaterial } from "@/scene/materials/pulsingNodeMaterial";

export class ProceduralTexturePointerHelper extends IntervalPointerHelper {
  constructor({ scene, domElement, params = {}, gui }) {
    super({ scene, domElement, params, gui });
  }

  _setMaterial() {
    const nodeMaterial = createPulsingNodeMaterial();

    const proceduralTexture = nodeMaterial.createProceduralTexture(
      216,
      this.scene
    );
    proceduralTexture.getAlphaFromRGB = true;

    this.pointer.material = new BABYLON.StandardMaterial(
      "pointerMaterial",
      this.scene
    );
    this.pointer.material.emissiveTexture = proceduralTexture;
    this.pointer.material.opacityTexture = proceduralTexture;
  }
}
