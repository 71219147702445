import * as BABYLON from "babylonjs";
import {
  clampLength,
  constrain,
  getObjectByName,
  mapPositionToPoint,
} from "@/scene/utils";
import TWEEN from "@tweenjs/tween.js";
import cloneDeep from "lodash.clonedeep";
import pageVisibilityObserver from "@/scene/PageVisibilityObserver";
import { KinevoScreenHelper } from "@/scene/kinevo/KinevoScreenHelper";
import { BoneAnimationParent } from "@/scene/rigged/BoneAnimationParent";
import { debounce } from "throttle-debounce";
import Vue from "vue";
import { kinevoToastMessages } from "@/scene/kinevo/kinevoToastMessages";

const positionMemoryFeature = "position-memory";
export const pointLockFeature = "point-lock";

export const UNBLOCKED_VALUE = "unblocked";

export const params = {
  lockToAxes: false,
  feature: pointLockFeature,
  x: 0.5,
  y: 0.5,
  z: 0.5,
  mass: 0.1,
  savedPosition: { x: 0.5, y: 0.5, z: 0.5 },
  active: false,
  pointLockFocus: false,
  isAnimating: false,
  blockedBy: UNBLOCKED_VALUE,
};

const defaultParams = cloneDeep(params);

const localParams = {
  defaultDuration: 2000,
  featureChangeDuration: 1000,
  lookAt: true,
  showTarget: 0,
  showBoundingBox: false,
};

const pointLockTarget = {
  position: new BABYLON.Vector3(20.25, 0.935, 1.02),
};

const pointLockSource = {
  position: new BABYLON.Vector3(-0.000815, 0.002105, 0.00098),
};

const humanParams = {
  position: new BABYLON.Vector3(18.84, 0.204, 1.04),
  rotation: new BABYLON.Vector3(0, -Math.PI, 0),
};

const interactOnButton = 0;

const positionMemoryButtonName = "positionMemoryButton";
const pointLockButtonName = "pointLockButton";
const changeFeatureButtonName = "changeFeatureButton";
const activationButtonName = "activationButton";
const lockToAxisButtonName = "lockToAxisButton";
const decreaseMassButtonName = "decreaseMassButton";
const increaseMassButtonName = "increaseMassButton";

const onMassChangeValue = 0.05;

const headObjectNames = ["Plane.001-P001_Kinevo_Body", "Plane.001-Metal"];

const interactObjectNames = [
  ...headObjectNames,
  positionMemoryButtonName,
  pointLockButtonName,
  changeFeatureButtonName,
  activationButtonName,
  lockToAxisButtonName,
  increaseMassButtonName,
  decreaseMassButtonName,
];

const maxDistanceToInteract = 2.5;

// Both animationsPointLock and animationsPositionMemory need to contain the same bones, if even not used in one of them:
const animationsPointLock = [
  {
    name: "Bone_arm",
    axis: new BABYLON.Vector3(1, 0, 0),
    rotationsLeftRight: [-1.1, 0.0, -1.4],
    rotationsBackFront: [-2.5, 0.0, -1.5],
  },
  {
    // Compensate Bone_arm movement here so that Bone_actuator002 stays vertical:
    name: "Bone_actuator.002",
    axis: new BABYLON.Vector3(1, 0, 0),
    rotationsLeftRight: [1.1, 0.0, 1.4],
    rotationsBackFront: [2.5, 0.0, 1.5],
  },
  {
    name: "Bone_arm.001",
    axis: new BABYLON.Vector3(0, 1, 0),
    rotationsBackFront: [-13.0, 0.0, 15.0],
  },
  {
    name: "Bone_arm.003",
    axis: new BABYLON.Vector3(0, 1, 0),
    rotationsLeftRight: [-24.1, 0.0, 22.1],
  },
  {
    name: "Bone_arm.006",
    axis: new BABYLON.Vector3(1, 0, 0),
    rotationsLeftRight: [-41.0, 0.0, 36.0],
  },
  {
    name: "Bone_arm.007",
    axis: new BABYLON.Vector3(0, 1, 0),
    rotationsLeftRight: [30.1, 0.0, -25.1],
  },
  {
    name: "Bone_hand",
    axis: new BABYLON.Vector3(0, 1, 0),
    rotationsBackFront: [-36.0, -0.1, 31.0],
  },
];

const animationsPositionMemory = [
  {
    name: "Bone_arm",
    axis: new BABYLON.Vector3(1, 0, 0),
    rotationsTopBottom: [-5.0, -0.1, 5.0],
  },
  {
    // Compensate Bone_arm movement here so that Bone_actuator002 stays vertical:
    name: "Bone_actuator.002",
    axis: new BABYLON.Vector3(1, 0, 0),
    rotationsTopBottom: [5.0, 0.1, -5.0],
  },
  {
    name: "Bone_arm.001",
    axis: new BABYLON.Vector3(0, 1, 0),
    rotationsBackFront: [-13.0, 0.0, 15.0],
  },
  {
    name: "Bone_arm.003",
    axis: new BABYLON.Vector3(0, 1, 0),
    rotationsLeftRight: [-15, 0.0, -15.1],
  },
  {
    name: "Bone_arm.006",
    axis: new BABYLON.Vector3(1, 0, 0),
    rotationsLeftRight: [-41.0, 0.0, 16.0],
  },
  {
    name: "Bone_arm.007", // hand z rotation
    axis: new BABYLON.Vector3(0, 1, 0),
    rotationsLeftRight: [25.1, 0.0, -30.1],
  },
  {
    name: "Bone_hand", // hand x rotation
    axis: new BABYLON.Vector3(0, 1, 0),
    rotationsBackFront: [-25.0, -0.1, 31.0],
  },
];

export default class KinevoController {
  onParamsChange = () => {};
  onRefreshBoundingBoxes = () => {};
  onPositionChange = () => {};
  onMassChange = () => {};
  onActivationStateChange = () => {};
  onFocusStateChange = () => {};
  onFeatureChange = () => {};
  onToastShow = () => {};

  constructor({
    gui,
    scene,
    camera,
    domElement,
    pointerLock,
    kinevoModel,
    humanModel,
    kinevoCollider,
    arrowModel,
  }) {
    this.gui = gui;
    this.scene = scene;
    this.camera = camera;
    this.domElement = domElement;
    this.pointerLock = pointerLock;

    this.boneAnimationsPointLockParent = null;
    this.boneAnimationsPositionMemoryParent = null;

    this.interactionObjectData = {};

    this.isDragging = false;
    this.isPicked = false;

    this.participantId = null;

    // use kinevo container to prevent issue with screens and shadow
    this.container = kinevoModel;
    this.container.position.z += 4.5;

    this._addGUIElements();

    this._setupKinevo(kinevoModel.getChildren()[0], kinevoCollider);
    this._setupSkeletonControls();
    this._setupHuman(humanModel);
    this._setupArrow(arrowModel);

    this.kinevoScreenHelper = new KinevoScreenHelper({
      kinevoModel: this.kinevo,
      scene: this.scene,
      gui: this.gui,
      container: this.container,
    });

    pageVisibilityObserver.addOnShowEvent(this._onWindowOpen);
  }

  _onWindowOpen = () => {
    this.updateBoneAnimations(false);
    this.forceLoopAnimation();
    this.refreshKinevoBoundingBoxes(false);
    this.kinevoScreenHelper.forceProjectionUpdate(
      params.feature === pointLockFeature
    );
  };

  _addGUIElements() {
    if (!this.gui) return;

    this.kinevoGUIFolder = this.gui.addFolder("Kinevo");

    this.kinevoGUIFolder
      .add(params, "active")
      .listen()
      .onChange(() => {
        if (params.active) {
          this.activate();
        } else {
          this.deactivate();
        }
      });

    this._addGUIControls();
    this.kinevoGUIControls.hide();
  }

  _addGUIControls() {
    this.kinevoGUIControls = this.kinevoGUIFolder.addFolder("Controls");

    this.kinevoGUIControls
      .add(params, "x", 0.0, 1.0)
      .listen()
      .onChange(() => {
        this.updateBoneAnimations();
      });

    this.kinevoGUIControls
      .add(params, "y", 0.0, 1.0)
      .listen()
      .onChange(() => {
        this.updateBoneAnimations();
      });
    this.kinevoGUIControls
      .add(params, "z", 0.0, 1.0)
      .listen()
      .onChange(() => {
        this.updateBoneAnimations();
      });

    this.kinevoGUIControls
      .add(params, "mass", onMassChangeValue, 1)
      .listen()
      .onChange(() => {
        this.updateBonesMass();
      });

    this.kinevoGUIControls
      .add(params, "lockToAxes")
      .listen()
      .onChange(() => {
        this.onParamsChange(params);
        this.showToast(kinevoToastMessages.LOCKED_TO_AXES);
      });

    this.kinevoGUIControls
      .add(params, "feature", {
        PointLock: pointLockFeature,
        PositionMemory: positionMemoryFeature,
      })
      .listen()
      .onChange(() => {
        params.x = defaultParams.x;
        params.y = defaultParams.y;
        params.z = defaultParams.z;
        if (params.pointLockFocus) {
          this.hideArrow();
          params.pointLockFocus = false;
          this.onFocusStateChange(params);
        } else {
          this.onParamsChange(params);
        }
        this.updateBoneAnimations();
        this.refreshKinevoBoundingBoxes(true, true);
      });

    // local params
    this.kinevoGUIControls
      .add(localParams, "lookAt")
      .listen()
      .onChange(() => {
        if (localParams.lookAt) {
          this._setLookAtOn();
        } else {
          this._setLookAtOff();
        }
      });

    this.kinevoGUIControls
      .add(localParams, "showTarget", { true: 1, false: 0 })
      .listen()
      .onChange(() => {
        this.sourcePoint.getChildren()[0].visibility = localParams.showTarget;
        this.targetPoint.getChildren()[0].visibility = localParams.showTarget;
      });

    this.kinevoGUIControls
      .add(localParams, "showBoundingBox")
      .listen()
      .onChange(() => {
        this.kinevo.getChildren((child) => {
          if (child.getClassName() === "Mesh") {
            child.showBoundingBox = localParams.showBoundingBox;
          }
        }, false);
      });
  }

  _setupHuman(human) {
    this.human = human;
    this.human.parent = this.container;
    this.human.position.copyFrom(humanParams.position);
    this.human.rotation.copyFrom(humanParams.rotation);

    const patientInner = this.human.getChildren()[0];
    if (patientInner) {
      const box = BABYLON.MeshBuilder.CreateBox(
        "patientBox",
        { width: 0.15, height: 0.15, depth: 0.03 },
        this.scene
      );
      box.position = new BABYLON.Vector3(0, -0.015, 0);
      box.parent = patientInner;
      box.material = new BABYLON.StandardMaterial(
        "patientBoxMaterial",
        this.scene
      );
      box.material.diffuseColor = new BABYLON.Color3(
        204 / 255,
        204 / 255,
        204 / 255
      );

      patientInner.scaling = new BABYLON.Vector3(0, 0, 0);
    }
  }

  _setupArrow(arrowModel) {
    const arrowNode = new BABYLON.TransformNode("arrowNode");
    arrowModel.scaling.set(0.9, -0.5, 0.9);
    arrowModel.parent = arrowNode;

    this.arrow = arrowNode;

    const scale = 0.01;
    this.arrow.scaling.set(scale, scale, scale);

    const rings = getObjectByName(this.arrow, "See_Tracker");
    if (rings) {
      const s = 0.4;
      rings.scaling.set(s, s * 2, s);
    }

    this.arrow.setEnabled(false);

    this._createTargetVisual();
    this._setLookAtOn();
  }

  _setLookAtOn() {
    const a = this.arrow.getChildren()[0];

    a.scaling.y = -Math.abs(a.scaling.y);

    // reset position and rotation
    a.position.set(0, 0, 0); // Bone_hand start
    a.rotation.set(0, 0, 0);
    a.rotationQuaternion = null;

    a.rotate(new BABYLON.Vector3(1, 0, 0), -Math.PI / 2);

    this.arrowInnerScale = a.scaling.clone();
  }

  _setLookAtOff() {
    const a = this.arrow.getChildren()[0];
    a.scaling.y = Math.abs(a.scaling.y);

    // set position and rotation
    a.position.set(-3, -21, 42);
    a.rotation.set(0, 0, 0);
    a.rotationQuaternion = null;
    a.rotate(new BABYLON.Vector3(1, 0, 0), -Math.PI / 2);

    this.arrowInnerScale = a.scaling.clone();
  }

  _createTargetVisual() {
    this.targetPoint = new BABYLON.TransformNode("targetPoint");
    this.targetPoint.parent = this.container;
    this.targetPoint.position.copyFrom(pointLockTarget.position);

    const targetVisual = new BABYLON.MeshBuilder.CreateSphere(
      "targetVisualSphere",
      { diameter: 0.004 },
      this.scene
    );
    targetVisual.material = new BABYLON.StandardMaterial(
      "targetVisualMaterial",
      this.scene
    );
    targetVisual.material.diffuseColor = new BABYLON.Color3.Red();
    targetVisual.parent = this.targetPoint;
    targetVisual.visibility = localParams.showTarget;

    this.sourcePoint = new BABYLON.TransformNode("sourcePoint");
    const sourceVisual = new BABYLON.MeshBuilder.CreateSphere(
      "sourceVisualSphere",
      { diameter: 0.00004 },
      this.scene
    );

    sourceVisual.material = new BABYLON.StandardMaterial(
      "sourceVisualMaterial",
      this.scene
    );
    sourceVisual.material.diffuseColor = new BABYLON.Color3.Blue();
    sourceVisual.parent = this.sourcePoint;
    this.sourcePoint.position.copyFrom(pointLockSource.position);
    this.sourcePoint.parent = this.headBone;
    sourceVisual.visibility = localParams.showTarget;
  }

  showArrow() {
    if (this.tweenArrow) this.tweenArrow.stop();
    this.arrow.setEnabled(true);

    const a = this.arrow.getChildren()[0];

    const s = this.arrowInnerScale;

    const length =
      BABYLON.Vector3.Distance(
        this.sourcePoint.absolutePosition,
        this.targetPoint.absolutePosition
      ) / this.arrow.scaling.x;

    a.scaling.set(0, 0, 0);
    a.position.z = length;

    this.tweenArrow = new TWEEN.Tween({ scale: 0.0, z: length })
      .to({ scale: 1.0, z: 0.0 }, 500.0)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate((p) => {
        a.scaling.set(p.scale, p.scale, p.scale);
        a.scaling.multiply(s);

        a.scaling.y = this.getArrowInnerScaleY() * p.scale;

        a.position.z = p.z;
      })
      .start();
  }

  hideArrow() {
    if (this.tweenArrow) this.tweenArrow.stop();

    const a = this.arrow.getChildren()[0];

    const s = this.arrowInnerScale;

    const length =
      BABYLON.Vector3.Distance(
        this.sourcePoint.absolutePosition,
        this.targetPoint.absolutePosition
      ) / this.arrow.scaling.x;

    a.scaling.copyFrom(this.arrowInnerScale);
    a.position.z = length;

    this.tweenArrow = new TWEEN.Tween({ scale: 1.0, z: 0.0 })
      .to({ scale: 0.0, z: length }, 250.0)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate((p) => {
        a.scaling.set(p.scale, p.scale, p.scale);
        a.scaling.multiply(s);

        a.position.z = p.z;
      })
      .onComplete(() => {
        this.arrow.setEnabled(false);
      })
      .start();
  }

  getArrowInnerScaleY() {
    const dist = BABYLON.Vector3.Distance(
      this.targetPoint.absolutePosition,
      this.sourcePoint.absolutePosition
    );
    return (-dist / 0.3) * 0.5;
  }

  _updateArrowPosition() {
    const { arrow, kinevo } = this;

    if (arrow && kinevo) {
      const a = arrow.getChildren()[0];

      if (localParams.lookAt) {
        // Scale arrow to fit between source and target:
        if (!this.tweenArrow || !this.tweenArrow.isPlaying()) {
          a.scaling.y = this.getArrowInnerScaleY();
        }

        arrow.position.copyFrom(this.targetPoint.absolutePosition);
        arrow.lookAt(this.sourcePoint.absolutePosition);
      } else {
        arrow.position.copyFrom(this.headBone.absolutePosition);
        arrow.rotationQuaternion = this.headBone.absoluteRotationQuaternion.clone();
        arrow.rotate(new BABYLON.Vector3(0, 1, 0), -0.45);
      }
    }
  }

  _setupKinevo(kinevoModel, kinevoCollider) {
    this.kinevo = kinevoModel;
    this.kinevo.name = "kinevo";

    this.kinevo.getChildren((child) => {
      if (child.getClassName() === "Mesh") {
        child.alwaysSelectAsActiveMesh = true;
      }
    }, false);

    this.headBone = getObjectByName(this.kinevo, "Bone_hand");

    kinevoCollider.getChildren().forEach((el) => {
      el.checkCollisions = true;
      el.isPickable = false;
      el.visibility = 0;
    });
    kinevoCollider.parent = this.container;

    this._setupKinevoControls();
    this._animatePulsingButton();
  }

  _setupKinevoControls() {
    this.buttonMaterial = new BABYLON.StandardMaterial(
      "buttonMaterial",
      this.scene
    );
    this.buttonMaterial.emissiveColor = new BABYLON.Color3.FromHexString(
      "#d4d4d4"
    );
    this.buttonMaterial.alpha = 0.4;
    this.buttonMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
    this.buttonMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
    this.buttonMaterial.ambientColor = new BABYLON.Color3(0, 0, 0);

    this.fundamentalButtons = [];

    this.positionMemoryButton = BABYLON.MeshBuilder.CreateCylinder(
      positionMemoryButtonName,
      { height: 0.0001, diameter: 0.00009 },
      this.scene
    );
    this.positionMemoryButton.position = new BABYLON.Vector3(
      -0.00027,
      0.004705,
      -0.001452
    );
    this.positionMemoryButton.rotate(new BABYLON.Vector3(0, 1, 0), 0.82);
    this.positionMemoryButton.rotate(
      new BABYLON.Vector3(1, 0, 0),
      Math.PI / 2 - 0.07
    );
    this.positionMemoryButton.parent = this.headBone;
    this.positionMemoryButton.material = this.buttonMaterial;
    this.positionMemoryButton.visibility = 0;

    this.pointLockButton = BABYLON.MeshBuilder.CreateCylinder(
      pointLockButtonName,
      { height: 0.0001, diameter: 0.00009 },
      this.scene
    );
    this.pointLockButton.position = new BABYLON.Vector3(
      -0.000255,
      0.004533,
      -0.001447
    );
    this.pointLockButton.rotate(new BABYLON.Vector3(0, 1, 0), 0.84);
    this.pointLockButton.rotate(
      new BABYLON.Vector3(1, 0, 0),
      Math.PI / 2 - 0.07
    );
    this.pointLockButton.parent = this.headBone;
    this.pointLockButton.material = this.buttonMaterial;
    this.pointLockButton.visibility = 0;

    this.changeFeatureButton = BABYLON.MeshBuilder.CreateSphere(
      changeFeatureButtonName,
      { diameter: 0.000175 },
      this.scene
    );
    this.changeFeatureButton.position = new BABYLON.Vector3(
      -0.000335,
      0.00462,
      -0.001356
    );
    this.changeFeatureButton.parent = this.headBone;
    this.changeFeatureButton.material = this.buttonMaterial;
    this.changeFeatureButton.visibility = 0;
    this.fundamentalButtons.push(this.changeFeatureButton);

    this.activationButton = BABYLON.MeshBuilder.CreateSphere(
      activationButtonName,
      { diameter: 0.000175 },
      this.scene
    );
    this.activationButton.position = new BABYLON.Vector3(
      -0.00032,
      -0.000513,
      -0.001336
    );
    this.activationButton.parent = this.headBone;
    this.activationButton.material = this.buttonMaterial;

    this.lockToAxisButton = BABYLON.MeshBuilder.CreateCylinder(
      lockToAxisButtonName,
      { height: 0.0001, diameter: 0.00009 },
      this.scene
    );
    this.lockToAxisButton.position = new BABYLON.Vector3(
      -0.000268,
      -0.0006,
      -0.001441
    );
    this.lockToAxisButton.rotate(new BABYLON.Vector3(0, 1, 0), 0.82);
    this.lockToAxisButton.rotate(
      new BABYLON.Vector3(1, 0, 0),
      Math.PI / 2 + 0.19
    );
    this.lockToAxisButton.material = this.buttonMaterial;
    this.lockToAxisButton.parent = this.headBone;
    this.lockToAxisButton.visibility = 0;
    this.fundamentalButtons.push(this.lockToAxisButton);

    this.increaseMassButton = BABYLON.MeshBuilder.CreateSphere(
      increaseMassButtonName,
      { diameter: 0.00015 },
      this.scene
    );
    this.increaseMassButton.position = new BABYLON.Vector3(
      -0.00049,
      -0.00042,
      -0.001163
    );
    this.increaseMassButton.parent = this.headBone;
    this.increaseMassButton.material = this.buttonMaterial;
    this.increaseMassButton.visibility = 0;
    this.fundamentalButtons.push(this.increaseMassButton);

    this.decreaseMassButton = BABYLON.MeshBuilder.CreateSphere(
      decreaseMassButtonName,
      { diameter: 0.00015 },
      this.scene
    );
    this.decreaseMassButton.position = new BABYLON.Vector3(
      -0.000522,
      -0.000564,
      -0.001178
    );
    this.decreaseMassButton.parent = this.headBone;
    this.decreaseMassButton.material = this.buttonMaterial;
    this.decreaseMassButton.visibility = 0;
    this.fundamentalButtons.push(this.decreaseMassButton);
  }

  updateButtonsVisibility() {
    if (params.active) {
      this.fundamentalButtons.forEach((button) => {
        button.visibility = 1;
      });

      const isPointLockFeature = params.feature === pointLockFeature;

      this.positionMemoryButton.visibility = isPointLockFeature ? 0 : 1;
      this.pointLockButton.visibility = isPointLockFeature ? 1 : 0;
    } else {
      this.fundamentalButtons.forEach((button) => {
        button.visibility = 0;
      });

      this.pointLockButton.visibility = 0;
      this.positionMemoryButton.visibility = 0;
    }
  }

  _setupSkeletonControls() {
    this.boneAnimationsPointLockParent = new BoneAnimationParent(
      this.kinevo,
      animationsPointLock,
      { precision: 0.4 }
    );

    this.boneAnimationsPositionMemoryParent = new BoneAnimationParent(
      this.kinevo,
      animationsPositionMemory,
      { precision: 0.4 }
    );
  }

  _getBoneAnimationsParent() {
    return params.feature === pointLockFeature
      ? this.boneAnimationsPointLockParent
      : this.boneAnimationsPositionMemoryParent;
  }

  _animatePulsingButton() {
    if (this.animatePulsingButtonTween) {
      this.animatePulsingButtonTween.stop();
    }

    this.animatePulsingButtonTween = new TWEEN.Tween({
      alpha: 0,
    })
      .to({ alpha: 0.4 }, 900)
      .repeat(Infinity)
      .yoyo(true)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onUpdate((p) => {
        this.buttonMaterial.alpha = p.alpha;
      })
      .start();
  }

  _stopAnimatePulsingButton() {
    this.animatePulsingButtonTween.stop();
  }

  assignInteraction() {
    this.pointerObservable = this.scene.onPointerObservable.add(
      (pointerInfo) => {
        const event = pointerInfo.event;

        const centerX = this.domElement.offsetWidth / 2;
        const centerY = this.domElement.offsetHeight / 2;

        if (
          pointerInfo.type === BABYLON.PointerEventTypes.POINTERDOWN &&
          event.button === interactOnButton &&
          (!this.pointerLock || this.pointerLock.isLocked) &&
          !params.isAnimating &&
          !this.isBlockedByOtherParticipant()
        ) {
          const pick = this.scene.pick(centerX, centerY);

          if (
            interactObjectNames.includes(pick?.pickedMesh?.name) &&
            pick.distance < maxDistanceToInteract
          ) {
            this.interactionObjectData.pointerDownMoment = performance.now();
            this.interactionObjectData.pickedMeshName = pick.pickedMesh.name;

            this.isDragging = true;
            this.isPicked = true;

            this.interactionObjectData.prevPoint = mapPositionToPoint(
              centerX,
              centerY,
              this.domElement
            );

            this.interactionObjectData.clientX = centerX;
            this.interactionObjectData.clientY = centerY;

            this.interactionObjectData.prevX = params.x;
            this.interactionObjectData.prevY = params.y;
            this.interactionObjectData.prevZ = params.z;

            this.interactionObjectData.positionStart = this.headBone.position.clone();
            this.interactionObjectData.rotationStart = this.headBone.rotationQuaternion.clone();

            if (params.active) {
              this.camera.detachControl();
            }
          }
        }

        if (this.isDragging) {
          if (pointerInfo.type === BABYLON.PointerEventTypes.POINTERUP) {
            // check if event is a tap
            if (
              this.interactionObjectData.clientX === centerX &&
              this.interactionObjectData.clientY === centerY &&
              performance.now() - this.interactionObjectData.pointerDownMoment <
                300
            ) {
              if (
                this.participantId &&
                params.blockedBy === this.participantId
              ) {
                params.blockedBy = UNBLOCKED_VALUE;
                this.onParamsChange(params);
              }

              if (
                headObjectNames.includes(
                  this.interactionObjectData.pickedMeshName
                ) &&
                params.active &&
                params.feature === positionMemoryFeature
              ) {
                this.recallPosition();
              }

              if (
                this.interactionObjectData.pickedMeshName ===
                  pointLockButtonName &&
                params.feature === pointLockFeature &&
                params.active
              ) {
                if (params.pointLockFocus) {
                  this.hideArrow();
                } else {
                  this.showArrow();
                }
                params.pointLockFocus = !params.pointLockFocus;
                this.onFocusStateChange(params);
                this.showToast(kinevoToastMessages.FOCUS);
              }

              if (
                this.interactionObjectData.pickedMeshName ===
                  positionMemoryButtonName &&
                params.feature === positionMemoryFeature &&
                params.active
              ) {
                this.savePosition();
              }

              if (
                this.interactionObjectData.pickedMeshName ===
                  changeFeatureButtonName &&
                params.active
              ) {
                this.updateFeature();
              }

              if (
                this.interactionObjectData.pickedMeshName ===
                activationButtonName
              ) {
                if (params.active) {
                  this.deactivate();
                } else {
                  this.activate();
                }
              }

              if (
                this.interactionObjectData.pickedMeshName ===
                  lockToAxisButtonName &&
                params.active
              ) {
                params.lockToAxes = !params.lockToAxes;
                this.onParamsChange(params);
                this.showToast(kinevoToastMessages.LOCKED_TO_AXES);
              }

              if (
                this.interactionObjectData.pickedMeshName ===
                  increaseMassButtonName &&
                params.active &&
                params.mass <= 1 - onMassChangeValue
              ) {
                params.mass += onMassChangeValue;
                this.updateBonesMass();
                this.showToastWithDebounce(
                  kinevoToastMessages.CHANGE_MASS(params.mass)
                );
              }

              if (
                this.interactionObjectData.pickedMeshName ===
                  decreaseMassButtonName &&
                params.active &&
                params.mass >= 2 * onMassChangeValue
              ) {
                params.mass -= onMassChangeValue;
                this.updateBonesMass();
                this.showToastWithDebounce(
                  kinevoToastMessages.CHANGE_MASS(params.mass)
                );
              }

              this.interactionObjectData.pickedMeshName = null;
            } else {
              if (params.active) {
                // synchronizes CPU and GPU mesh position
                this._getBoneAnimationsParent().onComplete = () => {
                  this.refreshKinevoBoundingBoxes();

                  if (!this.isPicked && !this.isBlockedByOtherParticipant()) {
                    params.blockedBy = UNBLOCKED_VALUE;
                    this.onParamsChange(params);
                  }
                };
              }
            }

            this.isDragging = false;
            this.isPicked = false;

            this.camera.attachControl();
          }

          // prevent move event if movementY and movementX are equals 0 at the same time
          if (
            pointerInfo.type === BABYLON.PointerEventTypes.POINTERMOVE &&
            params.active &&
            (event.movementY || event.movementX) &&
            !this.isBlockedByOtherParticipant()
          ) {
            if (this.isPicked) {
              this.isPicked = false;

              params.blockedBy = this.participantId;
              this.onParamsChange(params);
            }

            this.interactionObjectData.clientX += event.movementX;
            this.interactionObjectData.clientY += event.movementY;

            const point = mapPositionToPoint(
              this.interactionObjectData.clientX,
              this.interactionObjectData.clientY,
              this.domElement
            );

            const delta = {
              x: point.x - this.interactionObjectData.prevPoint.x,
              y: point.y - this.interactionObjectData.prevPoint.y,
            };

            this.interactionObjectData.prevPoint = point;

            if (params.lockToAxes) {
              params.x = constrain(
                this.interactionObjectData.prevX + delta.x * 5.0,
                0.0,
                1.0
              );
              params.z = constrain(
                this.interactionObjectData.prevZ - delta.y * 5.0,
                0.0,
                1.0
              );

              if (Math.abs(params.x - 0.5) > Math.abs(params.z - 0.5)) {
                params.z = 0.5;
              } else {
                params.x = 0.5;
              }
            } else {
              let temp = new BABYLON.Vector2(
                this.interactionObjectData.prevX + delta.x * 3.0,
                this.interactionObjectData.prevZ - delta.y * 2.0
              );
              temp.x -= 0.5;
              temp.y -= 0.5;
              clampLength(temp, 0, 0.5);

              params.x = temp.x + 0.5;
              params.z = temp.y + 0.5;

              if (params.feature !== pointLockFeature) {
                params.y = constrain(
                  this.interactionObjectData.prevY + delta.y * 2.0,
                  0.0,
                  1.0
                );
              }
            }

            this.interactionObjectData.prevX = params.x;
            this.interactionObjectData.prevY = params.y;
            this.interactionObjectData.prevZ = params.z;

            this.updateBoneAnimations();
          }
        }
      }
    );
  }

  savePosition(triggerParamsChange = true) {
    this.showToast(kinevoToastMessages.SAVED_POSITION);

    params.savedPosition = { x: params.x, y: params.y, z: params.z };

    if (triggerParamsChange) {
      this.onParamsChange(params);
    }
  }

  recallPosition() {
    this.showToast(kinevoToastMessages.RECALL_POSITION);

    this.animateTo({
      x: params.savedPosition.x,
      y: params.savedPosition.y,
      z: params.savedPosition.z,
    });
  }

  animateTo(target, props, triggerChanges = true) {
    const p = {
      delay: 0.0,
      duration: localParams.defaultDuration,
      onTweenComplete: () => {},
      onAnimationComplete: () => {},
      onUpdate: () => {},
      ...props,
    };

    let dist = 0;
    if (target.x !== undefined) dist += Math.abs(params.x - target.x);
    if (target.z !== undefined) dist += Math.abs(params.z - target.z);
    const duration = dist * p.duration;

    const tween = new TWEEN.Tween(params)
      .to(target, duration)
      .delay(p.delay)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onStart(() => {
        params.isAnimating = true;
        if (triggerChanges) {
          this.onParamsChange();
        }
      })
      .onUpdate(() => {
        this.updateBoneAnimations(triggerChanges);
        p.onUpdate();
      })
      .onComplete(() => {
        p.onTweenComplete();

        this._getBoneAnimationsParent().onComplete = () => {
          this.refreshKinevoBoundingBoxes(triggerChanges);

          p.onAnimationComplete();

          params.isAnimating = false;
          if (triggerChanges) {
            this.onParamsChange(params);
          }
        };
      })
      .start();

    return { tween, duration };
  }

  showHuman() {
    if (this.tweenHuman) this.tweenHuman.stop();

    this.tweenHuman = new TWEEN.Tween({
      scale: this.human.getChildren()[0].scaling.x,
    })
      .to({ scale: 6.0 }, 1500)
      .easing(TWEEN.Easing.Cubic.Out)
      .onUpdate((p) => {
        this.human.getChildren()[0].scaling = new BABYLON.Vector3(
          p.scale,
          p.scale,
          p.scale
        );
      })
      .start();
  }

  hideHuman() {
    if (this.tweenHuman) this.tweenHuman.stop();

    this.tweenHuman = new TWEEN.Tween({
      scale: this.human.getChildren()[0].scaling.x,
    })
      .to({ scale: 0.0 }, 1500)
      .delay(0)
      .easing(TWEEN.Easing.Cubic.InOut)
      .onUpdate((p) => {
        this.human.getChildren()[0].scaling = new BABYLON.Vector3(
          p.scale,
          p.scale,
          p.scale
        );
      })
      .start();
  }

  refreshKinevoBoundingBoxes(
    triggerEvent = true,
    forceAnimationLoopBeforeRefresh = false
  ) {
    if (forceAnimationLoopBeforeRefresh) {
      this.forceLoopAnimation();
    }

    this.kinevo.getChildren((child) => {
      if (child.refreshBoundingInfo) {
        child.refreshBoundingInfo(true);
      }
    }, false);

    if (triggerEvent) {
      this.onRefreshBoundingBoxes(forceAnimationLoopBeforeRefresh);
    }
  }

  forceLoopAnimation() {
    this._getBoneAnimationsParent().forceLoopUpdate();
  }

  disposeInteraction() {
    if (this.pointerObservable) {
      this.scene.onPointerObservable.remove(this.pointerObservable);
    }
  }

  updateBonesMass(triggerMassChange = true) {
    this.boneAnimationsPositionMemoryParent.setMass(params.mass);

    this.boneAnimationsPointLockParent.setMass(params.mass);

    this.kinevoScreenHelper.setMass(params.mass);

    if (triggerMassChange) {
      this.onMassChange(params);
    }
  }

  updateBoneAnimations = (triggerPositionChange = true) => {
    this._getBoneAnimationsParent().setPos(params.x, params.y, params.z);

    if (triggerPositionChange) {
      this.onPositionChange(params);
    }
  };

  loopBoneAnimations(delta) {
    this._getBoneAnimationsParent().loop(delta);
  }

  update = (delta) => {
    if (params.active || params.isAnimating) {
      this.loopBoneAnimations(delta);
      this.kinevoScreenHelper.updateProjection(params, delta);

      if (params.feature === pointLockFeature && params.pointLockFocus) {
        this._updateArrowPosition();
      }
    }
  };

  setOnParamsChangeListener(onParamsChange) {
    this.onParamsChange = onParamsChange;
  }

  setOnRefreshBoundingBoxesListener(onRefreshBoundingBoxes) {
    this.onRefreshBoundingBoxes = onRefreshBoundingBoxes;
  }

  setOnPositionChangeListener(onPositionChange) {
    this.onPositionChange = onPositionChange;
  }

  setOnMassChangeListener(onMassChange) {
    this.onMassChange = onMassChange;
  }

  setOnActivationStateChangeListener(onActivationStateChange) {
    this.onActivationStateChange = onActivationStateChange;
  }

  setOnFocusStateChangeListener(onFocusStateChange) {
    this.onFocusStateChange = onFocusStateChange;
  }

  setOnFeatureChangeListener(onFeatureChange) {
    this.onFeatureChange = onFeatureChange;
  }

  setOnToastShow(onToastShow) {
    this.onToastShow = onToastShow;
  }

  updateFeature(triggerFeatureChange = true) {
    if (params.pointLockFocus) {
      this.hideArrow();
      params.pointLockFocus = false;
    }

    this.showToast(
      kinevoToastMessages.CHANGED_FEATURE(
        params.feature === pointLockFeature ? "PositionMemory" : "PointLock"
      ),
      false
    );

    if (triggerFeatureChange) {
      this.onFeatureChange(params);

      this.animateTo(defaultParams.savedPosition, {
        duration: localParams.featureChangeDuration,
        onAnimationComplete: () => {
          params.feature =
            params.feature === positionMemoryFeature
              ? pointLockFeature
              : positionMemoryFeature;
          this.updateButtonsVisibility();
        },
      });
    }
  }

  activate(triggerActivationStateChange = true, showToast = true) {
    this.showHuman();
    this.kinevoScreenHelper.turnOn();
    params.active = true;

    if (showToast) {
      this.showToast(kinevoToastMessages.ACTIVATED, false);
    }

    this.updateButtonsVisibility();

    if (this.kinevoGUIControls) {
      this.kinevoGUIControls.show();
    }

    if (triggerActivationStateChange) {
      this.onActivationStateChange(params);
    }
  }

  showToastWithDebounce = debounce(250, false, (text) => {
    this.showToast(text);
  });

  showToast(text, triggerOnToastShow = true) {
    Vue.toasted.show(text, {
      theme: "toasted-primary",
      position: "top-left",
      duration: 1500,
    });

    if (triggerOnToastShow) {
      this.onToastShow(text);
    }
  }

  deactivate(triggerActivationStateChange = true, showToast = true) {
    this.hideHuman();
    this.kinevoScreenHelper.turnOff();

    if (showToast) {
      this.showToast(kinevoToastMessages.DEACTIVATED, false);
    }

    if (this.kinevoGUIControls) {
      this.kinevoGUIControls.hide();
    }

    let delay = 0;

    if (params.feature === pointLockFeature && params.pointLockFocus) {
      this.hideArrow();
      params.pointLockFocus = false;
      delay = 200;
    }

    params.active = false;
    this.updateButtonsVisibility();

    if (triggerActivationStateChange) {
      this.onActivationStateChange(params);

      this.animateTo(defaultParams.savedPosition, {
        delay,
        onAnimationComplete: () => {
          this._resetParams();

          this.updateBonesMass();
        },
      });
    }
  }

  _resetParams() {
    Object.assign(params, cloneDeep(defaultParams));
  }

  dispose() {
    pageVisibilityObserver.removeOnShowEvent(this._onWindowOpen);

    this.disposeInteraction();
  }

  setParticipantId(id) {
    this.participantId = id;
  }

  isBlockedByOtherParticipant() {
    return (
      params.blockedBy !== UNBLOCKED_VALUE &&
      params.blockedBy !== this.participantId &&
      this.participantId !== null
    );
  }
}
