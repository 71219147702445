import Viewer from "@/components/Viewer";
import VueRouter from "vue-router";
import Vue from "vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/viewer" },
  { path: "/viewer/:confName", props: true, component: Viewer },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
