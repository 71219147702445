import * as BABYLON from "babylonjs";

export const setValueByKey = (target, values) => {
  for (const [key, val] of Object.entries(values)) {
    if (val !== undefined && key in target) {
      target[key] = val;
    }
  }
};

export const isTouchDevice = () => {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
};

export const getObjectByName = (model, name) => {
  if (model.name === name) {
    return model;
  }

  const children = model.getChildren();

  for (let child of children) {
    const object = getObjectByName(child, name);

    if (object) {
      return object;
    }
  }

  return undefined;
};

export const DEG2RAD = Math.PI / 180;

export function constrain(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

export const mapPositionToPoint = (clientX, clientY, domElement) => {
  let rect;

  // IE 11 fix
  if (!domElement.parentElement) {
    rect = {
      x: 0,
      y: 0,
      left: 0,
      top: 0,
      width: 0,
      height: 0,
    };
  } else {
    rect = domElement.getBoundingClientRect();
  }

  const point = new BABYLON.Vector2();

  point.x = ((clientX - rect.left) / rect.width) * 2 - 1;
  point.y = -((clientY - rect.top) / rect.height) * 2 + 1;

  return point;
};

export const divideByValue = (vector, value) => {
  const scalar = 1 / value;

  return vector.scaleInPlace(scalar);
};

export const clampLength = (vector, min, max) => {
  const length = vector.length();

  return divideByValue(vector, length || 1).scaleInPlace(
    Math.max(min, Math.min(max, length))
  );
};

export const traverse = (model, func) => {
  if (typeof func !== "function") {
    return;
  }

  func(model);

  const children = model.getChildren();

  for (let child of children) {
    traverse(child, func);
  }
};

export const isVector3Array = (array) => {
  return (
    Array.isArray(array) &&
    array.length === 3 &&
    array.every((coord) => Number.isFinite(coord))
  );
};

export const isBetweenOrEquals = (value, min, max) => {
  return min <= value && value <= max;
};

export const round = (value, decimalPlaces) => {
  const decimalPlacesValue = Math.pow(10, decimalPlaces);

  return (
    Math.round((value + Number.EPSILON) * decimalPlacesValue) /
    decimalPlacesValue
  );
};
