import * as BABYLON from "babylonjs";

const defaultParams = {
  onButton: 2,
};

export class PointerHelper {
  onPosChange = () => {};

  constructor({ scene, domElement, params = {}, gui }) {
    this.scene = scene;
    this.domElement = domElement;
    this.gui = gui;

    Object.assign(this, defaultParams, params);

    this.pointerObserver = null;

    this.isDirty = true;

    this.assignPointerObserver();
  }

  assignPointerObserver() {
    this.pointerObserver = this.scene.onPointerObservable.add((pointerInfo) => {
      const event = pointerInfo.event;

      if (
        pointerInfo.type === BABYLON.PointerEventTypes.POINTERDOWN &&
        event.button === this.onButton
      ) {
        if (this.isDirty) {
          this.onDirtinessChange();
        }

        const centerX = this.domElement.offsetWidth / 2;
        const centerY = this.domElement.offsetHeight / 2;

        const pick = this.scene.pick(centerX, centerY);

        this.onPick(pick, pointerInfo);
      }
    });
  }

  onDirtinessChange() {
    this.isDirty = false;
  }

  // eslint-disable-next-line no-unused-vars
  onPick(pickEvent, pointerInfoEvent) {
    throw new Error("Cannot call abstract method");
  }

  // eslint-disable-next-line no-unused-vars
  updatePosition(params) {
    if (this.isDirty) {
      this.onDirtinessChange();
    }
  }

  disposePointerObserver() {
    if (this.pointerObserver) {
      this.scene.onPointerObservable.remove(this.pointerObserver);
    }
  }

  setOnPosChangeListener(onPosChange) {
    this.onPosChange = onPosChange;
  }
}
