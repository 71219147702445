<template>
  <transition name="fade">
    <div class="blocked-interaction-indicator" v-if="isVisible">
      <span>Another user interacts with the machine</span>
    </div>
  </transition>
</template>

<script>
export default {
  name: "InteractionIndicator",
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes pulse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.blocked-interaction-indicator {
  position: fixed;
  left: 50%;
  bottom: 90px;
  transform: translateX(-50%);

  font-family: Arial, sans-serif;

  padding: 8px 12px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.7);

  transition: opacity 0.3s;

  &:not(.fade-enter-active):not(.fade-leave-active) {
    animation: pulse 1.25s infinite ease-in-out alternate;
  }

  span {
    color: #fff;
    opacity: 0.7;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
