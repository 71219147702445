import SmoothFollow from "./SmoothFollow.js";
import { constrain, DEG2RAD, getObjectByName } from "@/scene/utils";

export default class BoneAnimation {
  constructor(
    model,
    { name, axis, rotationsLeftRight, rotationsBackFront, rotationsTopBottom }
  ) {
    this.name = name;
    this.axis = axis;
    this.rotationsLeftRight = rotationsLeftRight;
    this.rotationsBackFront = rotationsBackFront;
    this.rotationsTopBottom = rotationsTopBottom;

    this.enabled = true;

    this.angle = 0;
    this.angleSmooth = new SmoothFollow();
    this.angleSmooth.set(this.angle);
    this.setMass(0.1);

    this.bone = getObjectByName(model, name);

    if (this.bone) {
      this.defaultRotation = this.bone.rotationQuaternion.clone();
    } else {
      throw new Error("Bone '" + name + "' not found");
    }
  }

  setMass(mass) {
    this.mass = mass;
    this.angleSmooth.setMass(this.mass);
  }

  interpolate = (start, end, pos) => {
    return start + (end - start) * pos;
  };

  interpolate2 = (start, middle, end, pos) => {
    if (pos < 0.5) {
      return this.interpolate(start, middle, pos * 2.0);
    } else {
      return this.interpolate(middle, end, (pos - 0.5) * 2.0);
    }
  };

  setPos = (posLeftRight, posTopBottom, posBackFront) => {
    if (this.bone) {
      // If two rotations are set, interpolate between them and give more weight to the currently stronger direction:
      let angle1;
      let angle2;
      let pos1;
      let pos2;

      if (this.rotationsLeftRight) {
        if (!angle1) {
          pos1 = posLeftRight;
          angle1 = this.getAngle(this.rotationsLeftRight, pos1);
        } else if (!angle2) {
          pos2 = posLeftRight;
          angle2 = this.getAngle(this.rotationsLeftRight, pos2);
        }
      }

      if (this.rotationsTopBottom) {
        if (!angle1) {
          pos1 = posTopBottom;
          angle1 = this.getAngle(this.rotationsTopBottom, pos1);
        } else if (!angle2) {
          pos2 = posTopBottom;
          angle2 = this.getAngle(this.rotationsTopBottom, pos2);
        }
      }

      if (this.rotationsBackFront) {
        if (!angle1) {
          pos1 = posBackFront;
          angle1 = this.getAngle(this.rotationsBackFront, pos1);
        } else if (!angle2) {
          pos2 = posBackFront;
          angle2 = this.getAngle(this.rotationsBackFront, pos2);
        }
      }

      if (angle2) {
        const p = constrain(
          Math.abs(pos2 - 0.5) - Math.abs(pos1 - 0.5) + 0.5,
          0,
          1
        );

        this.angle = this.interpolate(angle1, angle2, p);
      } else {
        this.angle = angle1;
      }

      this.angleSmooth.set(this.angle);
    }
  };

  getAngle(rotations, pos) {
    if (rotations.length === 3) {
      return this.interpolate2(rotations[0], rotations[1], rotations[2], pos);
    } else {
      return this.interpolate(rotations[0], rotations[1], pos);
    }
  }

  loop = (deltaTime) => {
    if (!this.enabled) return;

    this.angleSmooth.set(this.angle);
    this.angleSmooth.loop(deltaTime);

    this.bone.rotationQuaternion.copyFrom(this.defaultRotation);
    const a = this.angleSmooth.getSmooth() * DEG2RAD;
    this.bone.rotate(this.axis, a);
  };

  forceLoopUpdate() {
    this.loop(this.mass);
  }

  isCompleted(precision = 0.1) {
    return this.angleSmooth.isCompleted(precision);
  }
}
