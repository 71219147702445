import { round } from "@/scene/utils";

export const kinevoToastMessages = {
  ACTIVATED: "Kinevo activated",
  DEACTIVATED: "Kinevo deactivated",
  LOCKED_TO_AXES: "Locked to axes",
  CHANGED_FEATURE: (feature) => `Changed feature to ${feature}`,
  SAVED_POSITION: "Saved position",
  RECALL_POSITION: "Recalled position",
  CHANGE_MASS: (mass) => `Changed mass to ${round(mass, 2)}`,
  FOCUS: "Focused arrow",
};
