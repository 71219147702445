import * as BABYLON from "babylonjs";
import { PointerHelper } from "@/scene/pointers/PointerHelper";
import { isVector3Array } from "@/scene/utils";
import TWEEN from "@tweenjs/tween.js";

const defaultParams = {
  width: 1,
  height: 1,
  translateYOffset: 0,
  animationDuration: 800,
  duration: 5000,
};

const RENDERING_GROUP_ID = 3;

export class IntervalPointerHelper extends PointerHelper {
  constructor({ scene, domElement, params = {}, gui }) {
    super({ scene, domElement, params, gui });

    Object.assign(this, defaultParams, params);

    this.pointer = null;

    this._durationTimeout = null;
    this._animationTween = null;

    this._setPointer();

    this._addGUIElements();
  }

  _addGUIElements() {
    if (!this.gui) return;

    this.basicPointerGUIFolder = this.gui.addFolder("Pointer");

    this.basicPointerGUIFolder
      .add(this.pointer, "renderingGroupId", {
        true: 0,
        false: RENDERING_GROUP_ID,
      })
      .name("depthTest")
      .listen();
  }

  _setPointer() {
    this.pointer = new BABYLON.MeshBuilder.CreatePlane(
      "pointerMaterial",
      {
        width: this.width,
        height: this.height,
        sideOrientation: BABYLON.Mesh.DOUBLESIDE,
      },
      this.scene
    );
    this.pointer.bakeTransformIntoVertices(
      BABYLON.Matrix.RotationX(Math.PI / 2)
    );
    this.pointer.isPickable = false;
    this.pointer.visibility = 0;
    this.pointer.renderingGroupId = RENDERING_GROUP_ID;

    this._setMaterial();
  }

  _setPosition(position, normal) {
    this.pointer.position.copyFrom(position);
    this.pointer.alignWithNormal(normal);
    this.pointer.translate(new BABYLON.Vector3(0, 1, 0), this.translateYOffset);
  }

  onPick(pickEvent) {
    const normal = pickEvent.getNormal(true);

    if (pickEvent.pickedPoint && normal) {
      this._setPosition(pickEvent.pickedPoint, normal);

      this.showPointerForInterval();

      this.onPosChange({
        position: pickEvent.pickedPoint.asArray(),
        normal: normal.asArray(),
      });
    }
  }

  updatePosition(params) {
    if (isVector3Array(params.position) && isVector3Array(params.normal)) {
      super.updatePosition();

      this._setPosition(
        BABYLON.Vector3.FromArray(params.position),
        BABYLON.Vector3.FromArray(params.normal)
      );

      this.showPointerForInterval();
    }
  }

  _setMaterial() {
    this.pointer.material = new BABYLON.StandardMaterial(
      "pointerMaterial",
      this.scene
    );
    this.pointer.material.diffuseColor = BABYLON.Color3.Black();
    this.pointer.material.alpha = 0;
  }

  animate(params) {
    if (this._animationTween) {
      this._animationTween.stop();
    }

    const p = {
      from: 0,
      to: 1,
      duration: 700,
      onStart: () => {},
      onComplete: () => {},
      ...params,
    };

    this._animationTween = new TWEEN.Tween({ alpha: p.from })
      .to({ alpha: p.to }, p.duration)
      .easing(TWEEN.Easing.Cubic.InOut)
      .onStart(() => {
        p.onStart();
      })
      .onUpdate((p) => {
        this.pointer.material.alpha = p.alpha;
      })
      .onComplete(() => {
        p.onComplete();
      })
      .start();
  }

  _showPointer(params) {
    this._stopAnimation();

    this.pointer.material.alpha = 0;

    this.animate({
      from: 0,
      to: 1,
      onStart: () => {
        this.pointer.visibility = 1;
      },
      ...params,
    });
  }

  _hidePointer() {
    this.animate({
      from: this.pointer.material.alpha,
      to: 0,
      onComplete: () => {
        this.pointer.visibility = 0;
      },
    });
  }

  _stopAnimation() {
    if (this._animationTween) {
      this._animationTween.stop();
    }
    if (this._durationTimeout) {
      clearTimeout(this._durationTimeout);
    }
  }

  showPointerForInterval() {
    this._showPointer({
      onComplete: () => {
        this._durationTimeout = setTimeout(() => {
          this.onPosChange({});
          this._hidePointer();
        }, this.duration);
      },
    });
  }
}
